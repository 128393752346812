import qs from 'qs';
import { tpfFundManagerConfig } from '@c2fo/common-config';
import { CapFinTokenService } from '../CapFinTokenService/CapFinTokenService';

/**
 * RedirectService
 * Handle the forming & execution of external links and redirects for the app.
 */
export class RedirectService {
  constructor(protected tokenService: CapFinTokenService) {
    this.tokenService = tokenService;
  }

  private fundManagerTokenParam = 'token';

  signUrlForFundManager(url: string): string {
    const anchor = document.createElement('a');
    anchor.href = url;
    const params = qs.parse(anchor.search, { ignoreQueryPrefix: true, strictNullHandling: true });
    if (params[this.fundManagerTokenParam]) delete params[this.fundManagerTokenParam];
    if (!this.tokenService.isTokenExpired()) {
      params.token = this.tokenService.getToken();
    }
    anchor.search = qs.stringify(params, { strictNullHandling: true });
    return anchor.href;
  }

  // CapFin Redirects

  getCapfinClientsUrl(): string {
    return this.signUrlForFundManager(`${tpfFundManagerConfig.TPF_FUND_MANAGER_BASE_URL}/capfin/clients/`);
  }

  getCapfinDebtorsUrl(): string {
    return this.signUrlForFundManager(`${tpfFundManagerConfig.TPF_FUND_MANAGER_BASE_URL}/capfin/debtors/`);
  }

  getReceiptUrl(): string {
    return this.signUrlForFundManager(`${tpfFundManagerConfig.TPF_FUND_MANAGER_BASE_URL}/capfin/all-receipts/uploads`);
  }

  getUnmappedCustomersUrl(): string {
    return this.signUrlForFundManager(
      `${tpfFundManagerConfig.TPF_FUND_MANAGER_BASE_URL}/capfin/client-debtor-mapping/unmapped`,
    );
  }

  getClientUrl(uuid: string): string {
    return this.signUrlForFundManager(
      `${tpfFundManagerConfig.TPF_FUND_MANAGER_BASE_URL}/capfin/clients/${uuid}/detail`,
    );
  }

  getCapfinReceiptsUrl(): string {
    return this.signUrlForFundManager(`${tpfFundManagerConfig.TPF_FUND_MANAGER_BASE_URL}/capfin/all-receipts/receipts`);
  }

  getCapfinReferenceRatesUrl(): string {
    return this.signUrlForFundManager(`${tpfFundManagerConfig.TPF_FUND_MANAGER_BASE_URL}/capfin/admin/reference-rates`);
  }

  // Receivables Finance Redirects

  getRFClientsUrl(): string {
    return this.signUrlForFundManager(
      `${tpfFundManagerConfig.TPF_FUND_MANAGER_BASE_URL}/capfin/receivables-finance/clients`,
    );
  }

  getRFUrl(): string {
    return this.signUrlForFundManager(
      `${tpfFundManagerConfig.TPF_FUND_MANAGER_BASE_URL}/capfin/receivables-finance/invoices/priority`,
    );
  }

  getAdvancesUrl(): string {
    return this.signUrlForFundManager(
      `${tpfFundManagerConfig.TPF_FUND_MANAGER_BASE_URL}/capfin/receivables-finance/advances/pending`,
    );
  }

  getRFFeesUrl(): string {
    return this.signUrlForFundManager(
      `${tpfFundManagerConfig.TPF_FUND_MANAGER_BASE_URL}/capfin/receivables-finance/fees`,
    );
  }

  getRFBalanceAdjustmentsUrl(): string {
    return this.signUrlForFundManager(
      `${tpfFundManagerConfig.TPF_FUND_MANAGER_BASE_URL}/capfin/receivables-finance/balance-adjustments`,
    );
  }

  getInitatedAdvancesUrl(): string {
    return this.signUrlForFundManager(
      `${tpfFundManagerConfig.TPF_FUND_MANAGER_BASE_URL}/capfin/receivables-finance/advances/initiated`,
    );
  }

  // Factoring Redirects

  getFactoringClientsUrl(): string {
    return this.signUrlForFundManager(
      `${tpfFundManagerConfig.TPF_FUND_MANAGER_BASE_URL}/capfin/receivables-finance/clients`,
    );
  }

  getFactoringUrl(): string {
    return this.signUrlForFundManager(
      `${tpfFundManagerConfig.TPF_FUND_MANAGER_BASE_URL}/capfin/factoring/invoices/priority`,
    );
  }

  getUnsettledClientsUrl(): string {
    return this.signUrlForFundManager(
      `${tpfFundManagerConfig.TPF_FUND_MANAGER_BASE_URL}/capfin/factoring/settlements/unsettled/?token=`,
    );
  }

  getFactoringFeesUrl(): string {
    return this.signUrlForFundManager(
      `${tpfFundManagerConfig.TPF_FUND_MANAGER_BASE_URL}/capfin/factoring/fees/client-fees`,
    );
  }

  getInitatedSettlementsUrl(): string {
    return this.signUrlForFundManager(
      `${tpfFundManagerConfig.TPF_FUND_MANAGER_BASE_URL}/capfin/factoring/settlements/initiated`,
    );
  }

  // Onboarding Redirects

  getOnboardingRFUrl(): string {
    return this.signUrlForFundManager(
      `${tpfFundManagerConfig.TPF_FUND_MANAGER_BASE_URL}/capfin/onboarding/rf/prequalification`,
    );
  }

  getOnboardingLendingUrl(): string {
    return this.signUrlForFundManager(
      `${tpfFundManagerConfig.TPF_FUND_MANAGER_BASE_URL}/markets/948/onboarding/lending`,
    );
  }

  // Lending Redirects

  getLendingMarketUrl(): string {
    return this.signUrlForFundManager(`${tpfFundManagerConfig.TPF_FUND_MANAGER_BASE_URL}/markets/948/loans/general`);
  }

  getLoansUrl(): string {
    return this.signUrlForFundManager(`${tpfFundManagerConfig.TPF_FUND_MANAGER_BASE_URL}/markets/948/loans/summary`);
  }

  getLoansInitiationUrl(): string {
    return this.signUrlForFundManager(`${tpfFundManagerConfig.TPF_FUND_MANAGER_BASE_URL}/markets/948/loans/management`);
  }

  getInstallmentsUrl(): string {
    return this.signUrlForFundManager(
      `${tpfFundManagerConfig.TPF_FUND_MANAGER_BASE_URL}/markets/948/loans/installments`,
    );
  }

  getTransactionsUrl(): string {
    return this.signUrlForFundManager(
      `${tpfFundManagerConfig.TPF_FUND_MANAGER_BASE_URL}/markets/948/loans/transactions`,
    );
  }

  getApprovedInvoicesUrl(): string {
    return this.signUrlForFundManager(
      `${tpfFundManagerConfig.TPF_FUND_MANAGER_BASE_URL}/markets/948/loans/ops/suppliers`,
    );
  }

  getManageBlacklistUrl(): string {
    return this.signUrlForFundManager(
      `${tpfFundManagerConfig.TPF_FUND_MANAGER_BASE_URL}/markets/948/loans/ops/blacklist`,
    );
  }

  // Connect Redirects
  getAccountingSystemsUrl(): string {
    return this.signUrlForFundManager(
      `${tpfFundManagerConfig.TPF_FUND_MANAGER_BASE_URL}/markets/948/connect/accounting-systems`,
    );
  }
}
