import { BaseDataService } from '../BaseDataService/BaseDataService';
import {
  Invoice,
  InvoiceStatus,
  InvoiceSupplierStatus,
  VerificationPriority,
} from '../../schemas/pendingInvoices.schema';
import { TpfSupplierApiHttpService } from '../TpfSupplierApiHttpService/TpfSupplierApiHttpService';
import { ProductType } from '../../schemas/enums.schema';
import { tpfSupplierApiConfig } from '@c2fo/common-config';
import { AxiosResponse } from 'axios';

const apiUrl = tpfSupplierApiConfig.TPF_SUPPLIER_API_ADMIN_BASE_URL;
export const invoiceService = `${apiUrl}/invoices`;
export const unprioritized = `${apiUrl}/invoices/prioritize-verification`;
export const transitionTemplate = `${apiUrl}/invoices/transition-template-download`;
export const invoiceApprovalTransitionSummary = `${invoiceService}/invoice-transition-summary`;
export const invoiceApprovalTransition = `${invoiceService}/invoice-transition`;

export interface PendingInvoicesByProduct {
  rfCount: number;
  factoringCount: number;
  unprioritizedCount: number;
  totalPendingSum: number;
  totalPendingInvoices: number;
  lastPrioritization: string | null;
}

export interface InvoiceApprovalSummary {
  approvedAndVerifiedCount: number;
  approvedAndVerifiedAmount: number;
  rejectedCount: number;
  rejectedAmount: number;
  approvedCount: number;
  approvedAmount: number;
  ignoredCount: number;
}

export const sizeLimits = 50;
/**
 * Service for interacting with client API endpoints.
 */

export class PendingInvoiceService extends BaseDataService<Invoice> {
  constructor(protected httpService: TpfSupplierApiHttpService) {
    super(httpService, invoiceService);
  }

  async prioritizeVerification(): Promise<Invoice[]> {
    try {
      const response = await this.httpService.post<Invoice[]>(unprioritized, {});
      return response.data;
    } catch (error) {
      throw new Error(`stub error message: ${error}`);
    }
  }

  /**
   * Return all pending invoices that need reviewing by product type.
   */
  // eslint-disable-next-line max-statements
  async getTotalPendingInvoices(): Promise<PendingInvoicesByProduct> {
    try {
      const rfInvoicesPromise = this.query({
        productType: ProductType.ReceivablesFinance,
        status: InvoiceStatus.PENDING,
        verificationPriority: VerificationPriority.HIGH,
      });
      const factoringInvoicesPromise = this.query({
        productType: ProductType.Factoring,
        status: InvoiceStatus.PENDING,
        verificationPriority: VerificationPriority.HIGH,
      });
      const unprioritizedInvoicesPromise = this.query({
        status: InvoiceStatus.PENDING,
        verificationPriority: VerificationPriority.NOT_PROCESSED,
      });
      const totalPendingPromise = this.query({
        status: InvoiceStatus.PENDING,
        size: 1,
      });
      const lastUpdatedPromise = this.query({
        status: InvoiceStatus.PENDING,
        verificationPriority: { in: [VerificationPriority.HIGH, VerificationPriority.LOW] },
        sort: ['updated,DESC'],
        size: 1,
      });
      const [rfInvoices, factoringInvoices, unprioritizedInvoices, totalPending, lastUpdated] = await Promise.all([
        rfInvoicesPromise,
        factoringInvoicesPromise,
        unprioritizedInvoicesPromise,
        totalPendingPromise,
        lastUpdatedPromise,
      ]);
      const factoringCount = factoringInvoices.data.length;
      const rfCount = rfInvoices.data.length;
      const unprioritizedCount = unprioritizedInvoices.data.length;
      let totalfactoringSum = 0;
      factoringInvoices.data.forEach((factoringInvoice) => {
        totalfactoringSum += factoringInvoice.amount;
      });
      let totalRFSum = 0;
      rfInvoices.data.forEach((rfInvoice) => {
        totalRFSum += rfInvoice.amount;
      });
      const totalPendingSum = totalfactoringSum + totalRFSum;
      const totalPendingInvoices = totalPending.meta.totalElements;
      let lastPrioritization = null;
      if (totalPendingInvoices !== 0 && lastUpdated.data.length !== 0) {
        lastPrioritization = lastUpdated.data[0].updated;
      }
      return { rfCount, factoringCount, unprioritizedCount, totalPendingSum, totalPendingInvoices, lastPrioritization };
    } catch (error) {
      throw new Error(`stub error message: ${error}`);
    }
  }

  /**
   * Build the appropriate string to link to / open for downloading _all_ pending invoices for both products in a format
   * that is appropriate for approval upload file.
   */
  buildDownloadAllPendingUrl(): string {
    return this.httpService.formatDownloadUrl(transitionTemplate, {
      status: InvoiceSupplierStatus.PENDING,
    });
  }

  async getInvoiceApprovalUploadSummary(file: File): Promise<InvoiceApprovalSummary> {
    const token = this.httpService.getToken();
    const summaryPromise = await this.httpService.post(invoiceApprovalTransitionSummary, {
      data: file,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return summaryPromise.data as InvoiceApprovalSummary;
  }

  async updateInvoiceApprovalStatus(file: File): Promise<AxiosResponse> {
    const token = this.httpService.getToken();
    return this.httpService.post(invoiceApprovalTransition, {
      data: file,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
