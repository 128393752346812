import { TokenService } from '@c2fo/react-services';
import { legacyC2foAdminUiConfig } from '@c2fo/common-config';

export class CapFinTokenService extends TokenService {
  isNoAuthMode(): boolean {
    return process.env.NODE_ENV === 'development' && process.env.REACT_APP_NOAUTH_MODE === '1';
  }

  isTokenExpired(): boolean {
    if (this.isNoAuthMode()) {
      return false;
    }

    return super.isTokenExpired();
  }

  /**
   * Attempt a re-login.
   * 1. Delete the invalid token
   * 2. redirect to admin.c2fo login with a redirect url specifying previous url and a token identifier.
   */
  relogin(): void {
    this.clearToken();
    const encodedRedirect = encodeURIComponent(window.location.href);
    window.location.replace(
      `${legacyC2foAdminUiConfig.LEGACY_C2FO_ADMIN_UI_URL}/login?redirect-to=${encodedRedirect}&tokenQueryParam=token`,
    );
  }

  /**
   * Explicit logout action.
   * 1. Clear auth token from local storage
   * 2. Redirect to app.c2fo to clear any tokens for that app as well.
   */
  logout(): void {
    this.clearToken();
    window.location.replace(`${legacyC2foAdminUiConfig.LEGACY_C2FO_ADMIN_UI_URL}/logout`);
  }
}
