import React, { ReactNode } from 'react';
import { StoreToken } from '@c2fo/react-components';
import { useServices } from '../../services';

/**
 * Wrapper of <StoreToken> to decouple services and components libraries.
 * See <StoreToken/> for more details.
 */
export function StoreTokenWrapper(props: { children: ReactNode }) {
  const { capFinTokenService } = useServices();
  const setTokenFunction = (token: string) => {
    capFinTokenService.setToken(token);
  };

  return <StoreToken setToken={setTokenFunction}>{props.children}</StoreToken>;
}
