import React from 'react';
import { makeStyles, Theme, Logo, Link } from '@c2fo/react-components';

const barHeight = 48;
const barHeightSm = 68;
const useStyles = makeStyles<Theme>((theme) => ({
  placeholderElement: {
    height: barHeight,
    [theme.breakpoints.up('sm')]: {
      height: barHeightSm,
    },
  },
  bar: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    backgroundColor: theme.palette.secondary.dark,
    color: 'white',
    padding: theme.spacing(0, 2),
    zIndex: 2,
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 8),
    },
  },
  container: {
    backgroundColor: theme.palette.secondary.dark,
    color: 'white',
    height: barHeight,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: theme.breakpoints.values.lg,
    margin: '0 auto 0',
    [theme.breakpoints.up('sm')]: {
      height: barHeightSm,
    },
  },
  logo: {
    height: 16,
    [theme.breakpoints.up('sm')]: {
      height: 24,
    },
  },
  logoLink: {
    lineHeight: 0,
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    overflow: 'hidden',
  },
  title: {
    color: 'white',
    fontWeight: 'bold',
    display: 'block',
    marginLeft: theme.spacing(1),
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));

export const Navigation: React.FC = (props) => {
  const classes = useStyles({});

  return (
    <div className={classes.placeholderElement}>
      <nav className={classes.bar}>
        <div className={classes.container}>
          <div className={classes.logoContainer}>
            <Link href="/" className={classes.logoLink}>
              <Logo color="white" className={classes.logo} />
            </Link>
          </div>
        </div>
      </nav>
    </div>
  );
};
