import { BaseDataService } from '../BaseDataService/BaseDataService';
import { TpfSupplierApiHttpService } from '../TpfSupplierApiHttpService/TpfSupplierApiHttpService';
import { ClockService } from '../ClockService/ClockService';
import { Advance, AdvanceStatus } from '../../schemas/advance.schema';
import { tpfSupplierApiConfig } from '@c2fo/common-config';
import { set, startOfToday } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

const apiUrl = tpfSupplierApiConfig.TPF_SUPPLIER_API_ADMIN_BASE_URL;
export const getAdvanceRequest = `${apiUrl}/advances`;
export interface PendingAdvances {
  advanceTotal: number;
  count: number;
  advanceDateTime?: string;
}
export interface TodaysPendingAdvances {
  count: number;
  showAlert: boolean;
}
export interface YesterdaysInitiatedAdvances {
  count: number;
  showAlert: boolean;
}

export const ADMIN_TZ = 'America/Chicago';
/**
 * Service for interacting with client API endpoints.
 */
export class AdvanceService extends BaseDataService<Advance> {
  constructor(protected httpService: TpfSupplierApiHttpService, protected clockService: ClockService) {
    super(httpService, getAdvanceRequest);
  }

  async getTotalPendingAdvances(): Promise<PendingAdvances> {
    try {
      const advances = await this.query({
        status: {
          in: [AdvanceStatus.PENDING, AdvanceStatus.AUTHORIZED],
        },
      });
      let advanceTotal = 0;
      advances.data.forEach((advance) => {
        advanceTotal += advance.advanceAmount;
      });
      const count = advances.data.length;
      return { advanceTotal, count };
    } catch (error) {
      throw new Error(`error getting pending advances: ${error}`);
    }
  }

  private getTimeInUSCentral(date: Date): Date {
    return zonedTimeToUtc(date, ADMIN_TZ);
  }

  async hasPendingAdvanceForToday(): Promise<TodaysPendingAdvances> {
    try {
      const today = this.clockService.getTimeNow();
      const onePM = set(today, { hours: 13, minutes: 0, seconds: 0 });
      let showAlert = false;
      let count = 0;
      const advances = await this.query({
        status: {
          in: [AdvanceStatus.PENDING, AdvanceStatus.AUTHORIZED],
        },
        created: {
          lte: onePM.toISOString(),
        },
      });
      if (advances.data.length > 0) {
        const timeOfNow = this.clockService.getTimeNow();
        const threePMCentral = this.getTimeInUSCentral(set(today, { hours: 15, minutes: 0, seconds: 0 }));
        if (timeOfNow < threePMCentral) {
          showAlert = true;
          count = advances.data.length;
        }
      }
      return { showAlert, count };
    } catch (error) {
      throw new Error(`error getting today's pending advances: ${error}`);
    }
  }

  async hasInitiatedAdvanceForYesterday(): Promise<YesterdaysInitiatedAdvances> {
    try {
      const midnightToday = startOfToday();
      const advances = await this.query({
        status: AdvanceStatus.INITIATED,
        created: {
          lte: midnightToday.toISOString(),
        },
      });
      let count = 0;
      let showAlert = false;
      if (advances.data.length > 0) {
        count = advances.data.length;
        showAlert = true;
      }
      return { showAlert, count };
    } catch (error) {
      throw new Error(`error getting yesterday's initated advances: ${error}`);
    }
  }
}
