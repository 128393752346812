import { TpfSupplierApiHttpService } from '../TpfSupplierApiHttpService/TpfSupplierApiHttpService';
import { FactoringClientBalance } from '../../schemas';
import { BaseDataService } from '../BaseDataService/BaseDataService';
import { tpfSupplierApiConfig } from '@c2fo/common-config';

const apiUrl = tpfSupplierApiConfig.TPF_SUPPLIER_API_ADMIN_BASE_URL;

export const factoringCreditLimitPath = `${apiUrl}/factoring-client-balances`;

export interface FactoringCreditLimit {
  factoringCreditLimit: FactoringClientBalance[];
}

export class FactoringClientBalanceService extends BaseDataService<FactoringClientBalance> {
  constructor(httpService: TpfSupplierApiHttpService) {
    super(httpService, factoringCreditLimitPath);
  }

  async getFactoringCreditLimit(): Promise<FactoringCreditLimit> {
    try {
      const factoringClientApproachingLimitPromise = await this.query({
        isClosed: false,
        creditUtilizationPercent: {
          gte: 50,
        },
      });
      const [factoringClientApproachingLimit] = await Promise.all([factoringClientApproachingLimitPromise]);
      let factoringCreditLimit = factoringClientApproachingLimit.data;
      return { factoringCreditLimit };
    } catch (error) {
      throw new Error(`error getting clients: ${error}`);
    }
  }
}
