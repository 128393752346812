import { BaseDataService } from '../BaseDataService/BaseDataService';
import { tpfSupplierApiConfig } from '@c2fo/common-config';
import { TpfSupplierApiHttpService } from '../TpfSupplierApiHttpService/TpfSupplierApiHttpService';
import {
  UnsettledClient,
  UnsettledBalance,
  ClientSettlement,
  SettlementStatus,
} from '../../schemas/settlements.schema';
import { ClockService } from '../ClockService/ClockService';
import { set } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { PageableResponse } from '../../schemas';

const apiUrl = tpfSupplierApiConfig.TPF_SUPPLIER_API_ADMIN_BASE_URL;
export const unsettledClientUrl = `${apiUrl}/unsettled-clients`;
export const clientSettlementsUrl = `${apiUrl}/client-settlements`;

export interface TodaysPendingSettlements {
  count: number;
  showAlert: boolean;
}
export interface YesterdaysInitatedSettlements {
  count: number;
  showAlert: boolean;
}

export const ADMIN_TZ = 'America/Chicago';

export class SettlementsService extends BaseDataService<UnsettledClient> {
  constructor(protected httpService: TpfSupplierApiHttpService, protected clockService: ClockService) {
    super(httpService, unsettledClientUrl);
  }

  private getTimeInUSCentral(date: Date): Date {
    return zonedTimeToUtc(date, ADMIN_TZ);
  }

  async getUnsettledBalances(): Promise<UnsettledBalance> {
    try {
      const balances = await this.query();
      let negativeBalanceTotal = 0;
      let positiveBalanceTotal = 0;
      let negativeCount = 0;
      let positiveCount = 0;
      balances.data.forEach((balance) => {
        let balanceTotal = balance.totalUnsettledAmount;
        if (balanceTotal < 0) {
          negativeBalanceTotal += balanceTotal;
          negativeCount += 1;
        } else if (balanceTotal > 0) {
          positiveBalanceTotal += balanceTotal;
          positiveCount += 1;
        }
      });
      return { negativeBalanceTotal, positiveBalanceTotal, negativeCount, positiveCount };
    } catch (error) {
      throw new Error(`error getting unsettled balances: ${error}`);
    }
  }

  async hasPendingSettlmentsForToday(): Promise<TodaysPendingSettlements> {
    try {
      const today = this.clockService.getTimeNow();
      const onePM = set(today, { hours: 13, minutes: 0, seconds: 0 });
      const threePMCentral = this.getTimeInUSCentral(set(today, { hours: 15, minutes: 0, seconds: 0 }));
      let count = 0;
      let showAlert = false;
      const settlements = await this.httpService
        .get<PageableResponse<ClientSettlement[]>>(clientSettlementsUrl, {
          params: this.httpService.serializeQueryParams({
            settlementStatus: SettlementStatus.PENDING,
            created: {
              lte: onePM.toISOString(),
            },
          }),
        })
        .then((response) => response.data);
      if (settlements.data.length > 0 && today < threePMCentral) {
        showAlert = true;
        count = settlements.data.length;
      }
      return { showAlert, count };
    } catch (error) {
      throw new Error(`error getting pending settlments: ${error}`);
    }
  }

  async hasInitiatedSettlmentsForYesterday(): Promise<YesterdaysInitatedSettlements> {
    try {
      const today = this.clockService.getTimeNow();
      const midnightCentralToday = this.getTimeInUSCentral(set(today, { hours: 0, minutes: 0, seconds: 0 }));
      const settlements = await this.httpService.get<ClientSettlement[]>(clientSettlementsUrl, {
        params: this.httpService.serializeQueryParams({
          settlementStatus: SettlementStatus.INITIATED,
          created: {
            lte: midnightCentralToday.toISOString(),
          },
        }),
      });
      let count = 0;
      let showAlert = false;
      if (settlements.data.length > 0) {
        count = settlements.data.length;
        showAlert = true;
      }
      return { showAlert, count };
    } catch (error) {
      throw new Error(`error getting yesterday's initated advances: ${error}`);
    }
  }
}
