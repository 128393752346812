import React from 'react';
import {
  Card,
  makeStyles,
  TypeWidgetHeader,
  TypeHelper,
  TypeCallout,
  Grid,
  Dialog,
  PrimaryButton,
  DialogActions,
  DialogContent,
  Link,
  TypeBase,
  Skeleton,
  useTheme,
} from '@c2fo/react-components';
import { useAsync } from 'react-use';
import { useServices } from '../../services';
import { TopBorderCard } from '../TopBorderCard/TopBorderCard';
import { RfClientBalance, FactoringClientBalance } from '../../schemas';

const useStyles = makeStyles((theme) => ({
  root: {
    borderTopStyle: 'solid',
    borderTopColor: theme.palette.grey['300'],
    borderTopWidth: '10px',
    borderRadius: '5px',
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  amount: {
    fontSize: theme.typography.pxToRem(40),
  },
  titleOverflow: {
    display: 'block',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    '&> p': {
      display: 'block',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },
  sectionHeader: {
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.grey['600'],
    borderBottomWidth: theme.spacing(0.3),
    background: theme.palette.background.canvas,
    padding: theme.spacing(2),
  },
  header: {
    padding: theme.spacing(2),
    background: theme.palette.secondary.light,
  },
  companyGrid: {
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.grey['300'],
    borderBottomWidth: theme.spacing(0.1),
    padding: theme.spacing(2),
  },
  overdueChip: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '& svg': { fill: 'white' },
  },
  overdueGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  datePadding: {
    paddingRight: theme.spacing(2),
  },
  dialogContentOverride: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
  },
}));

export function CompanyContracts(props: {
  utilization: number;
  name: string;
  creditLimit: number;
  creditUsed: number;
  uuid: string;
}) {
  const classes = useStyles();
  const { currencyService, redirectService } = useServices();
  return (
    <Grid container item xs={12} className={classes.companyGrid}>
      <Grid item xs={6}>
        <TypeBase>{props.name}</TypeBase>
      </Grid>
      <Grid item xs={3}>
        <TypeBase>{currencyService.format('en', 'USD', props.creditUsed, { stripDecimals: true })}</TypeBase>
        <TypeHelper>
          Limit: {currencyService.format('en', 'USD', props.creditLimit, { stripDecimals: true })}
        </TypeHelper>
      </Grid>
      <Grid item xs={2}>
        <TypeBase>{Math.round(props.utilization)}%</TypeBase>
        <TypeHelper>Utilization</TypeHelper>
      </Grid>
      <Grid item md={2} lg={4} className={classes.overdueGrid}>
        <Link data-testid="redirect" href="#" onClick={() => window.open(redirectService.getClientUrl(props.uuid))}>
          <TypeWidgetHeader>View</TypeWidgetHeader>
        </Link>
      </Grid>
    </Grid>
  );
}

export function ContractMaturityDialog(props: {
  rfCredit: RfClientBalance[];
  factoringCredit: FactoringClientBalance[];
  total: number;
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Link data-testid="contract-list" href="#" onClick={handleClickOpen}>
        <TypeWidgetHeader>View</TypeWidgetHeader>
      </Link>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={'paper'}
        aria-labelledby="Scroll dialog of contracts"
        aria-describedby="list of due contracts"
      >
        <span className={classes.header}>
          <TypeWidgetHeader customColor="white">Clients Approaching Credit Limit ({props.total})</TypeWidgetHeader>
        </span>
        <DialogContent classes={{ root: classes.dialogContentOverride }}>
          <Grid container>
            {props.rfCredit.map((contract) => (
              <CompanyContracts
                utilization={contract.creditUtilizationPercent}
                name={contract.clientName}
                creditLimit={contract.clientCreditLimit}
                creditUsed={contract.clientCreditLimit - contract.availabilityBalance}
                uuid={contract.uuid}
                key={contract.uuid}
              />
            ))}
            {props.factoringCredit.map((contract) => (
              <CompanyContracts
                utilization={contract.creditUtilizationPercent}
                name={contract.clientName}
                creditLimit={contract.creditLimit}
                creditUsed={contract.creditLimit - contract.creditAvailable}
                uuid={contract.uuid}
                key={contract.uuid}
              />
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <PrimaryButton data-testid="close-button" onClick={handleClose}>
            close
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export const CreditLimit: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { rfClientService, factoringClientService } = useServices();

  const asyncState = useAsync(async () => {
    const [rfCreditLimit, factoringCreditLimit] = await Promise.all([
      rfClientService.getRFCreditLimit(),
      factoringClientService.getFactoringCreditLimit(),
    ]);
    return { rfCreditLimit, factoringCreditLimit };
  }, []);

  if (asyncState.loading)
    return (
      <TopBorderCard topBorderColor={theme.palette.grey['300']}>
        <Skeleton variant="rect" height="110px" />
      </TopBorderCard>
    );
  if (asyncState.error || !asyncState.value) return <div>can't get value </div>;

  const { rfCreditLimit } = asyncState.value.rfCreditLimit;
  const { factoringCreditLimit } = asyncState.value.factoringCreditLimit;
  const totalReachingCreditLimit = rfCreditLimit.length + factoringCreditLimit.length;
  let totalRFUtilization = 0;
  let totalFactoringUtilization = 0;
  rfCreditLimit.forEach((rf) => {
    totalRFUtilization += rf.creditUtilizationPercent;
  });
  factoringCreditLimit.forEach((factor) => {
    totalFactoringUtilization += factor.creditUtilizationPercent;
  });
  const averageUtilization = Math.round((totalRFUtilization + totalFactoringUtilization) / totalReachingCreditLimit);
  return (
    <Card className={classes.root}>
      <TypeCallout>{totalReachingCreditLimit}</TypeCallout>
      <Grid container>
        <Grid item xs={9}>
          <TypeHelper>Clients Approaching Credit Limit</TypeHelper>
        </Grid>
        <Grid item xs={3}>
          <ContractMaturityDialog
            rfCredit={rfCreditLimit}
            factoringCredit={factoringCreditLimit}
            total={totalReachingCreditLimit}
          />
        </Grid>
        <Grid item xs={9}>
          <TypeHelper>Average Utilization: {averageUtilization}% </TypeHelper>
        </Grid>
      </Grid>
    </Card>
  );
};
