import React from 'react';
import { TaskManagerGrid } from '../../components/TaskManagerGrid/TaskManagerGrid';
import { SideNestedNav } from '../../components/SideNestedNav/SideNestedNav';

export const AMDashboardPage: React.FC = () => {
  return (
    <div>
      <SideNestedNav />
      <TaskManagerGrid />
    </div>
  );
};
