import React from 'react';
import {
  Card,
  makeStyles,
  TypeWidgetHeader,
  TypeCallout,
  TypeHelper,
  Grid,
  Link,
  BaseChip,
  UploadIcon,
  ExclamationCircleIcon,
  Skeleton,
  useTheme,
} from '@c2fo/react-components';
import { useServices } from '../../services';
import { useAsync } from 'react-use';
import { TopBorderCard } from '../TopBorderCard/TopBorderCard';
import { format, differenceInDays } from 'date-fns';
import { formatUTCAsCentral } from '../../i18n/dateFormat';

const useStyles = makeStyles((theme) => ({
  root: {
    borderTopStyle: 'solid',
    borderTopWidth: '.6rem',
    borderTopColor: theme.palette.warning.main,
    borderRadius: '.3rem',
  },
  topHalf: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottomStyle: 'solid',
    borderBottomWidth: '.03rem',
    borderBottomColor: theme.palette.grey['300'],
  },
  bottomHalf: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  prior: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(0.5),
  },
  totalFilesChip: {
    backgroundColor: theme.palette.warning.main,
    color: 'white',
    '& svg': { fill: 'white' },
  },
  bottomHalfNull: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(3.6),
    paddingBottom: theme.spacing(3.6),
  },
}));

export const PendingRemittance: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { pendingRemittanceService, currencyService, redirectService } = useServices();
  const asyncPendingRemittanceServiceState = useAsync(async () => {
    return pendingRemittanceService.getPendingRemittance();
  });
  if (asyncPendingRemittanceServiceState.loading)
    return (
      <TopBorderCard topBorderColor={theme.palette.warning.main}>
        <Skeleton variant="rect" height="150px" />
      </TopBorderCard>
    );
  if (asyncPendingRemittanceServiceState.error || !asyncPendingRemittanceServiceState.value)
    return <div>can't get value </div>;
  const {
    totalAmount,
    totalReceipts,
    oldestReceipt,
    newestReceipt,
    totalFiles,
  } = asyncPendingRemittanceServiceState.value;

  if (oldestReceipt !== null && newestReceipt !== null) {
    return (
      <Card className={classes.root}>
        <Grid container className={classes.topHalf}>
          <Grid container item md={10} lg={10} style={{ paddingBottom: '0.5rem' }}>
            <Grid item xs={5}>
              <TypeWidgetHeader>Remittance Pending </TypeWidgetHeader>
            </Grid>
            <Grid item md={5}>
              {totalFiles !== 0 ? (
                <BaseChip
                  data-testid="chip"
                  className={classes.totalFilesChip}
                  size="small"
                  label={totalFiles + ' Files Total'}
                />
              ) : null}
            </Grid>
            <TypeHelper>
              Last Updated: {newestReceipt.slice(5, 10).replace(/-/, '/')} at{' '}
              {formatUTCAsCentral(new Date(newestReceipt).toISOString(), 'p')}
            </TypeHelper>
          </Grid>

          <Grid item md={4} lg={2} className={classes.prior}>
            <UploadIcon fontSize="inherit" />
            {totalAmount !== 0 ? (
              <Link data-testid="receipt-url" href="#" onClick={() => window.open(redirectService.getReceiptUrl())}>
                <TypeWidgetHeader>View</TypeWidgetHeader>
              </Link>
            ) : (
              <TypeWidgetHeader>View</TypeWidgetHeader>
            )}
          </Grid>
        </Grid>

        <Grid container className={classes.bottomHalf}>
          <Grid container item xs={12}>
            <Grid item md={4} lg={4}>
              <TypeCallout>{currencyService.format('en', 'USD', totalAmount, { stripDecimals: true })}</TypeCallout>
              <TypeHelper>Total Amount</TypeHelper>
            </Grid>
            <Grid item md={4} lg={4}>
              <TypeCallout>{totalReceipts}</TypeCallout>
              <TypeHelper>Total Receipt</TypeHelper>
            </Grid>
            <Grid item md={4} lg={4}>
              <TypeCallout>{differenceInDays(new Date(), new Date(oldestReceipt.slice(0, 10)))} days</TypeCallout>
              <TypeHelper>Oldest Receipt</TypeHelper>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    );
  }
  return (
    <Card className={classes.root}>
      <Grid container className={classes.topHalf}>
        <Grid container item xs={10}>
          <Grid item xs={12}>
            <TypeWidgetHeader>Remittance Pending </TypeWidgetHeader>
          </Grid>
          <TypeHelper>
            Last Updated: {format(new Date(), 'dd/MM/yy')} at {formatUTCAsCentral(new Date().toISOString(), 'p')}
          </TypeHelper>
        </Grid>

        <Grid item md={2} lg={4} className={classes.prior}>
          <UploadIcon fontSize="inherit" />
          <Link data-testid="receipt-url" href="#" onClick={() => window.open(redirectService.getReceiptUrl())}>
            <TypeWidgetHeader>View</TypeWidgetHeader>
          </Link>
        </Grid>
      </Grid>

      <Grid container className={classes.bottomHalfNull}>
        <Grid item xs={12} className={classes.prior}>
          <ExclamationCircleIcon fontSize="inherit" />
          <TypeWidgetHeader>No receipts pending</TypeWidgetHeader>
        </Grid>
      </Grid>
    </Card>
  );
};
