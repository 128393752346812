import React from 'react';
import {
  Card,
  makeStyles,
  TypeWidgetHeader,
  TypeHelper,
  TypeCallout,
  Grid,
  Link,
  Skeleton,
  useTheme,
} from '@c2fo/react-components';
import { formatUTCAsCentral } from '../../i18n/dateFormat';
import { useQuery } from 'react-query';
import { useServices } from '../../services';
import { TopBorderCard } from '../TopBorderCard/TopBorderCard';
import { format } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  root: {
    borderTopStyle: 'solid',
    borderTopColor: theme.palette.grey['300'],
    borderTopWidth: '10px',
    borderRadius: '5px',
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  amount: {
    fontSize: theme.typography.pxToRem(40),
  },
  titleOverflow: {
    display: 'block',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    '&> p': {
      display: 'block',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },
  sectionHeader: {
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.grey['300'],
    borderBottomWidth: theme.spacing(0.3),
    background: theme.palette.background.canvas,
    padding: theme.spacing(2),
  },
  header: {
    padding: theme.spacing(2),
    background: theme.palette.secondary.light,
  },
  companyGrid: {
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.grey['300'],
    borderBottomWidth: theme.spacing(0.1),
    padding: theme.spacing(2),
  },
  overdueChip: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '& svg': { fill: 'white' },
  },
  overdueGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  datePadding: {
    paddingRight: theme.spacing(2),
  },
  dialogContentOverride: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
  },
}));

export const UnmappedCustomers: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { clientDebtorService, redirectService } = useServices();

  const { isLoading, error, data } = useQuery('unmappedCustomers', () => {
    return clientDebtorService.getUnmappedCustomers();
  });
  if (isLoading || error || !data) {
    return (
      <TopBorderCard topBorderColor={theme.palette.grey['300']}>
        <Skeleton variant="rect" height="110px" />
      </TopBorderCard>
    );
  }

  const { count, firstCreated } = data;

  const goToUnmappedCustomers = () => {
    window.open(redirectService.getUnmappedCustomersUrl());
  };

  if (count !== 0) {
    let firstCreatedTime = firstCreated ? formatUTCAsCentral(firstCreated, 'MM/yy h:mm a') : 'N/A';
    return (
      <Card className={classes.root}>
        <TypeCallout>{count}</TypeCallout>
        <Grid container item md={5} lg={10}>
          <Grid item md={5} lg={10}>
            <TypeHelper>Unknown Company IDs</TypeHelper>
            <Grid item md={5} lg={10} className={classes.titleOverflow}>
              <TypeHelper>Last Updated: {firstCreatedTime}</TypeHelper>
            </Grid>
          </Grid>
          <Grid item md={1} lg={2}>
            <Link data-testid="view-unmapped-customers" onClick={goToUnmappedCustomers}>
              <TypeWidgetHeader>Mapping</TypeWidgetHeader>
            </Link>
          </Grid>
        </Grid>
      </Card>
    );
  }
  return (
    <Card className={classes.root}>
      <TypeCallout>{count}</TypeCallout>
      <Grid container item xs={10}>
        <Grid item md={2} lg={4}>
          <TypeHelper>Unknown Company IDs</TypeHelper>
          <TypeHelper>
            Last Updated: {format(new Date(), 'MM/dd')} at {formatUTCAsCentral(new Date().toISOString(), 'p')}
          </TypeHelper>
        </Grid>
        <Grid item md={2} lg={4}>
          <Link data-testid="view-unmapped-customers" onClick={goToUnmappedCustomers}>
            <TypeWidgetHeader>Mapping</TypeWidgetHeader>
          </Link>
        </Grid>
      </Grid>
    </Card>
  );
};
