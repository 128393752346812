import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { getAuthenticatedRoute } from '@c2fo/react-components';
import { AMDashboardPage } from './pages';

export const Router: React.FC = () => {
  const AuthenticatedRoute = getAuthenticatedRoute({
    isAuthenticated: () => true,
    redirectTo: '/',
  });

  return (
    <Switch>
      <Route exact path="/" component={AMDashboardPage} />
      <AuthenticatedRoute path="/am-dashboard" component={AMDashboardPage} />
    </Switch>
  );
};
