import { ClientDebtor, UnmappedClientDebtors } from '../../schemas/clientDebtor.schema';
import { BaseDataService } from '../BaseDataService/BaseDataService';
import { TpfSupplierApiHttpService } from '../TpfSupplierApiHttpService/TpfSupplierApiHttpService';
import { tpfSupplierApiConfig } from '@c2fo/common-config';

const apiUrl = tpfSupplierApiConfig.TPF_SUPPLIER_API_ADMIN_BASE_URL;
export const getUnmapped = `${apiUrl}/unmapped-customer-views`;

export class ClientDebtorService extends BaseDataService<ClientDebtor> {
  constructor(httpService: TpfSupplierApiHttpService) {
    super(httpService, getUnmapped);
  }

  async getUnmappedCustomers(): Promise<UnmappedClientDebtors> {
    try {
      const customers = await this.query({
        sort: ['firstUploaded,DESC'],
      });
      const count = customers.data.length;
      if (count === 0) {
        let firstCreated = null;
        return { count, firstCreated };
      } else {
        let firstCreated = customers.data[0].firstUploaded;
        return { count, firstCreated };
      }
    } catch (error) {
      throw new Error(`error getting unmapped customers: ${error}`);
    }
  }
}
