export enum SettlementStatus {
  PENDING = 'PENDING',
  INITIATED = 'INITIATED',
  SENT = 'SENT',
  CANCELED = 'CANCELED',
}

export enum RemittanceType {
  ACH = 'ACH',
  CHECK = 'CHECK',
  CASHIER_CHECK = 'CASHIER_CHECK',
  WIRE = 'WIRE',
  OTHER = 'OTHER',
}

export interface UnsettledClient {
  uuid: string;
  clientName: string;
  feeAmount: number | null;
  invoicePurchaseAmount: number | null;
  invoiceRebateAmount: number | null;
  invoiceUnderpaymentAmount: number | null;
  invoiceChargeBackAmount: number | null;
  passthroughAmount: number | null;
  invoiceFeeAmount: number | null;
  totalUnsettledAmount: number;
}

export interface UnsettledBalance {
  negativeBalanceTotal: number;
  positiveBalanceTotal: number;
  negativeCount: number;
  positiveCount: number;
}

export interface ClientSettlement {
  clientContractUuid: string;
  clientName: string;
  clientUuid: string;
  created: string;
  feeAmount: null | number;
  invoiceAdvanceAmount: null | number;
  invoiceAmount: null | number;
  invoiceDiscountFeeAmount: null | number;
  invoiceFeeAmount: null | number;
  invoicePurchasePrice: null | number;
  invoiceRebateAmount: null | number;
  invoiceUnderpaymentAmount: null | number;
  issuedDate: string;
  passthroughAmount: null | number;
  remittanceFee: null | number;
  remittanceType: RemittanceType;
  settlementStatus: SettlementStatus;
  totalAmount: number | null;
  updated: null | string;
  uuid: string;
}
