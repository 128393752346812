import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Divider, SnackCard, makeStyles } from '@c2fo/react-components';
import { useServices } from '../../services/index';

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(1.2),
    marginBottom: theme.spacing(1.2),
  },
  // Note: To override the fixed max width of the snackcard,
  // the "styling" on the "root" is added below
  root: {},
  ctaLink: {
    float: 'right',
  },
}));

function usePendingAdvancesAlert() {
  const { advanceService, redirectService } = useServices();
  const { data } = useQuery('pendingAdvancesForToday', async () => {
    return advanceService.hasPendingAdvanceForToday();
  });

  const classes = useStyles();

  if (data && data.showAlert) {
    const { count } = data;
    return {
      element: (
        <>
          You have {count} Pending Advances. To view and complete them before 3 PM{' '}
          <a className={classes.ctaLink} data-testid="go-to-advances" href={redirectService.getAdvancesUrl()}>
            Click here
          </a>{' '}
        </>
      ),
      showAlert: data.showAlert,
    };
  } else {
    return {
      element: <></>,
      showAlert: false,
    };
  }
}

function usePendingSettlementsAlert() {
  const { settlementsService, redirectService } = useServices();
  const { data } = useQuery('pendingSettlementsForToday', async () => {
    return settlementsService.hasPendingSettlmentsForToday();
  });

  const classes = useStyles();

  if (data && data.showAlert) {
    const { count } = data;
    return {
      element: (
        <>
          There is an unsettled balance for {count} clients.{' '}
          <a
            className={classes.ctaLink}
            data-testid="go-to-unsettled-clients"
            href={redirectService.getUnsettledClientsUrl()}
          >
            Click here
          </a>{' '}
          <Divider className={classes.divider} />
        </>
      ),
      showAlert: data.showAlert,
    };
  } else {
    return {
      element: <></>,
      showAlert: false,
    };
  }
}

function useYesterdaysInitiatedAdvanceAlert() {
  const { advanceService, redirectService } = useServices();
  const { data } = useQuery('initiatedAdvancesForYesterday', async () => {
    return advanceService.hasInitiatedAdvanceForYesterday();
  });

  const classes = useStyles();

  if (data && data.showAlert) {
    return {
      element: (
        <>
          Initated Advances were created yesterday. Please check payment state of advances listed.{' '}
          <a
            className={classes.ctaLink}
            data-testid="go-to-initiated-advances"
            href={redirectService.getInitatedAdvancesUrl()}
          >
            Click here
          </a>
        </>
      ),
      showAlert: data.showAlert,
    };
  } else {
    return {
      element: <></>,
      showAlert: false,
    };
  }
}

function useYesterdaysInitiatedSettlementsAlert() {
  const { settlementsService, redirectService } = useServices();
  const { data } = useQuery('initiatedSettlementsForYesterday', async () => {
    return settlementsService.hasInitiatedSettlmentsForYesterday();
  });

  const classes = useStyles();

  if (data && data.showAlert) {
    const { count } = data;
    return {
      element: (
        <>
          {count} Initated Settlements were created. Please check payment state of advances listed.{' '}
          <a
            className={classes.ctaLink}
            data-testid="go-to-initiated-settlements"
            href={redirectService.getInitatedSettlementsUrl()}
          >
            Click here
          </a>
        </>
      ),
      showAlert: true,
    };
  } else {
    return {
      element: <></>,
      showAlert: false,
    };
  }
}

// eslint-disable-next-line complexity
export const TimeSensitiveAlerts: React.FC = () => {
  const classes = useStyles();
  let [showTimeSensitiveAlerts, setTimeSensitiveAlerts] = useState<boolean>(true);

  let pendingAdvancesAlert = usePendingAdvancesAlert();
  let pendingSettlementsAlert = usePendingSettlementsAlert();
  let yesterdaysInitiatedAdvanceAlert = useYesterdaysInitiatedAdvanceAlert();
  let yesterdaysInitiatedSettlementsAlert = useYesterdaysInitiatedSettlementsAlert();

  if (
    (!pendingAdvancesAlert.showAlert &&
      !pendingSettlementsAlert.showAlert &&
      !yesterdaysInitiatedAdvanceAlert.showAlert &&
      !yesterdaysInitiatedSettlementsAlert.showAlert) ||
    !showTimeSensitiveAlerts
  ) {
    return null;
  } else {
    return (
      <SnackCard
        data-testid="time-sensitive-alerts"
        title="You have time sensitive actions due"
        variant="warning"
        open
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        onClose={() => {
          setTimeSensitiveAlerts(false);
        }}
        className={classes.root}
      >
        {pendingAdvancesAlert.element}
        {pendingSettlementsAlert.showAlert && pendingAdvancesAlert.showAlert ? (
          <Divider className={classes.divider} />
        ) : (
          <></>
        )}
        {pendingSettlementsAlert.element}
        {yesterdaysInitiatedAdvanceAlert.showAlert && pendingSettlementsAlert.showAlert ? (
          <Divider className={classes.divider} />
        ) : (
          <></>
        )}
        {yesterdaysInitiatedAdvanceAlert.element}
        {yesterdaysInitiatedSettlementsAlert.showAlert && yesterdaysInitiatedAdvanceAlert.showAlert ? (
          <Divider className={classes.divider} />
        ) : (
          <></>
        )}
        {yesterdaysInitiatedSettlementsAlert.element}
      </SnackCard>
    );
  }
};
