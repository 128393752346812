import { TpfSupplierApiHttpService } from '../TpfSupplierApiHttpService/TpfSupplierApiHttpService';
import { Client } from '../../schemas';
import { BaseDataService } from '../BaseDataService/BaseDataService';
import { tpfSupplierApiConfig } from '@c2fo/common-config';
import { format, addDays } from 'date-fns';

const apiUrl = tpfSupplierApiConfig.TPF_SUPPLIER_API_ADMIN_BASE_URL;
export const clientsPath = `${apiUrl}/clients`;

export interface NearDueContracts {
  next30: Client[];
  next60: Client[];
  next90: Client[];
}

/**
 * Service for interacting with client API endpoints.
 */
export class ClientService extends BaseDataService<Client> {
  constructor(httpService: TpfSupplierApiHttpService) {
    super(httpService, clientsPath);
  }

  async getDueContracts(): Promise<NearDueContracts> {
    try {
      const next30DuePromise = await this.query({
        contractExpirationDate: {
          lte: format(addDays(new Date(), 30), 'yyyy-MM-dd'),
        },
        sort: ['contractExpirationDate,ASC'],
        isApprovedAndConfigured: true,
        isClosed: false,
      });
      const next60DuePromise = await this.query({
        contractExpirationDate: {
          lte: format(addDays(new Date(), 60), 'yyyy-MM-dd'),
          gte: format(addDays(new Date(), 31), 'yyyy-MM-dd'),
        },
        sort: ['contractExpirationDate,ASC'],
        isApprovedAndConfigured: true,
        isClosed: false,
      });
      const next90DuePromise = await this.query({
        contractExpirationDate: {
          lte: format(addDays(new Date(), 90), 'yyyy-MM-dd'),
          gte: format(addDays(new Date(), 61), 'yyyy-MM-dd'),
        },
        sort: ['contractExpirationDate,ASC'],
        isApprovedAndConfigured: true,
        isClosed: false,
      });
      const [next30Due, next60Due, next90Due] = await Promise.all([
        next30DuePromise,
        next60DuePromise,
        next90DuePromise,
      ]);
      let next30 = next30Due.data;
      let next60 = next60Due.data;
      let next90 = next90Due.data;

      return { next30, next60, next90 };
    } catch (error) {
      throw new Error(`error getting clients: ${error}`);
    }
  }
}
