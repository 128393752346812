export enum StagedReceiptUploadStatus {
  PENDING = 'PENDING',
}
export interface StagedReceiptUpload {
  uuid: string;
  fileName: string;
  s3Path: string;
  status: StagedReceiptUploadStatus;
  lastUpdated: string;
  totalAmount: number;
  totalCount: number;
  pendingReviewCount: number;
  successfulCount: number;
  downloadPath: string | null;
  parseErrors: string;
}
