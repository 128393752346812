export enum AdvanceStatus {
  CANCELED = 'CANCELED',
  PENDING = 'PENDING',
  AUTHORIZED = 'AUTHORIZED',
  INITIATED = 'INITIATED',
  SENT = 'SENT',
  PAID = 'PAID',
}

export interface Advance {
  uuid: string;
  clientUuid: string;
  advanceAmount: number;
  advanceDate: string;
  created: string;
  createdByEmailAddress: string;
  divisionUuid: string;
  notes: string;
  status: AdvanceStatus;
  transactionFee: number;
  transferMethod: string;
  type: string;
  updated: string;
}
