import { PageableResponse, Query } from '../../schemas/http.schema';
import { TpfSupplierApiHttpService } from '../TpfSupplierApiHttpService/TpfSupplierApiHttpService';

/**
 * Service for interacting with API endpoints.
 */
export class BaseDataService<T> {
  constructor(protected httpService: TpfSupplierApiHttpService, protected basePath: string) {}

  async query(params: Query<T> = {}): Promise<PageableResponse<T>> {
    const response = await this.httpService.get<PageableResponse<T>>(this.basePath, {
      params: this.httpService.serializeQueryParams(params),
    });
    return response.data;
  }
}
