import { TpfSupplierApiHttpService } from '../TpfSupplierApiHttpService/TpfSupplierApiHttpService';
import { RfClientBalance } from '../../schemas';
import { BaseDataService } from '../BaseDataService/BaseDataService';
import { tpfSupplierApiConfig } from '@c2fo/common-config';

const apiUrl = tpfSupplierApiConfig.TPF_SUPPLIER_API_ADMIN_BASE_URL;

export const rfCreditLimitPath = `${apiUrl}/rf-client-balance-views`;

export interface RFCreditLimit {
  rfCreditLimit: RfClientBalance[];
}

export class RFClientBalanceService extends BaseDataService<RfClientBalance> {
  constructor(httpService: TpfSupplierApiHttpService) {
    super(httpService, rfCreditLimitPath);
  }

  async getRFCreditLimit(): Promise<RFCreditLimit> {
    try {
      const rfClientApproachingLimitPromise = await this.query({
        isActive: true,
        creditUtilizationPercent: {
          gte: 50,
        },
      });
      const [rfClientApproachingLimit] = await Promise.all([rfClientApproachingLimitPromise]);
      let rfCreditLimit = rfClientApproachingLimit.data;
      return { rfCreditLimit };
    } catch (error) {
      throw new Error(`error getting clients: ${error}`);
    }
  }
}
