import React from 'react';
import { TypeBase, makeStyles, Paper } from '@c2fo/react-components';
import { Navigation } from '../../components';

/**
 * As this is global app error boundary, we cannot rely on
 * the Material UI `theme` property being set appropriately here.
 */
const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    height: '100vh',
    position: 'relative',
    width: '100%',
  },
  container: {
    position: 'absolute',
    zIndex: 1,
    top: '10%',
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
  paper: {
    alignItems: 'center',
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    marginTop: 64,
    padding: 24,
    position: 'relative',
    zIndex: 1,
  },
}));

export const GlobalErrorPage: React.FC = () => {
  const classes = useStyles({});

  return (
    <div className={classes.wrapper}>
      <Navigation />
      <div className={classes.container}>
        <Paper className={classes.paper}>
          <TypeBase isError align="center">
            The application has encountered an unknown error.
          </TypeBase>
        </Paper>
      </div>
    </div>
  );
};
