import { ProductType } from './enums.schema';

export enum InvoiceStatus {
  STAGED = 'STAGED',
  INELIGIBLE = 'INELIGIBLE',
  UNMAPPED = 'UNMAPPED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  SENT = 'SENT',
  CLOSED = 'CLOSED',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  REMOVED = 'REMOVED',
  PURCHASED = 'PURCHASED',
  CHARGED_BACK = 'CHARGED_BACK',
  WRITTEN_DOWN = 'WRITTEN_DOWN',
  DRAFT = 'DRAFT',
  CUSTOMER_REJECTED = 'CUSTOMER_REJECTED',
}
export enum InvoiceSupplierStatus {
  PAID = 'PAID',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  PENDING = 'PENDING',
  DRAFT = 'DRAFT',
  STAGED = 'STAGED',
  OPEN = 'OPEN',
  PURCHASED = 'PURCHASED',
  APPROVED = 'APPROVED',
  REMOVED = 'REMOVED',
  CHARGED_BACK = 'CHARGED_BACK',
  WRITTEN_DOWN = 'WRITTEN_DOWN',
  REJECTED = 'REJECTED',
}
export enum VerificationPriority {
  HIGH = 'HIGH',
  LOW = 'LOW',
  NOT_PROCESSED = 'NOT_PROCESSED',
}
export interface Invoice {
  amount: number;
  approvedDate: string | null;
  debtorName: string | null;
  debtorUuid: string | null;
  divisionUuid: string;
  dueDate: string;
  invoiceId: string;
  orderId: string;
  netAmount: number | null;
  receivedDeductionAmount: number | null;
  receivedDiscountAmount: number | null;
  receivedGrossAmount: number | null;
  receivedNetAmount: number | null;
  removedDate: string | null;
  status: InvoiceStatus;
  supplierDisplayStatus: InvoiceSupplierStatus;
  transactionDate: string;
  updated: string;
  uuid: string;
  verificationPriority: VerificationPriority;
  productType: ProductType;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RfInvoice extends Invoice {}

export interface FactoringInvoice extends Invoice {
  advanceRate: number;
  discountFee: number | null;
  expectedDueDate: string | null;
  invoiceSettlementAmount: number | null;
  invoiceSettlementDate: number | null;
  isPurchased: boolean;
  lateFeeAmount: number | null;
  paymentDate: string | null;
  purchasePrice: number | null;
  purchasedDate: string | null;
  rate: number;
  receivedTotalAmount: number;
}
