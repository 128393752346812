import React, { useState } from 'react';
import clsx from 'clsx';
import { useQuery, useMutation, queryCache } from 'react-query';
import { format } from 'date-fns';
import {
  BaseChip,
  makeStyles,
  TypeWidgetHeader,
  TypeHelper,
  PrimaryButton,
  Link,
  Box,
  MoreVertIcon,
  TypeCallout,
  TypeLabel,
  Menu,
  MenuItem,
  IconButton,
  Grid,
  ExclamationCircleIcon,
  Skeleton,
  TypeBase,
  useTheme,
} from '@c2fo/react-components';
import { formatUTCAsCentral } from '../../i18n/dateFormat';
import { TopBorderCard } from '../TopBorderCard/TopBorderCard';
import { useServices } from '../../services';
import { InvoiceApprovalTransitionSummary } from '../InvoiceApprovalTransitionSummary/InvoiceApprovalTransitionSummary';
import { useId } from '@reach/auto-id';
import { InvoiceApprovalSummary } from '../../services/PendingInvoicesService/PendingInvoicesService';

const useStyles = makeStyles((theme) => ({
  body: {
    display: 'flex',
    flexDirection: 'row',
  },
  '@keyframes spin': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(359deg)',
    },
  },
  spinProgress: {
    animation: '$spin 2s linear infinite',
  },
  iconPadding: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  productCountMenu: {
    display: 'flex',
    alignItems: 'center',
  },
  unprioriizedChip: {
    backgroundColor: theme.palette.warning.main,
    color: 'white',
    '& svg': { fill: 'white' },
  },
  topHalf: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottomStyle: 'solid',
    borderBottomWidth: '.03rem',
    borderBottomColor: theme.palette.grey['300'],
  },
  bottomHalf: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  prior: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(0.5),
  },
  bottomHalfNull: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(3.6),
    paddingBottom: theme.spacing(3.6),
  },
}));

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function PendingInvoicesMenu(props: { factoringCount: number; rfCount: number }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const classes = useStyles();
  const fileUploadId = useId();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const { redirectService, pendingInvoicesService } = useServices();
  let [isShowingTransitionDialog, setIsShowingTransitionDialog] = useState<boolean>(false);
  let [invoiceApprovalSummary, setInvoiceApprovalSummary] = useState<undefined | InvoiceApprovalSummary>();
  let [fileUpload, setFileUpload] = useState<File>();

  const handleRedirectFactoring = () => {
    window.open(redirectService.getFactoringUrl());
    setAnchorEl(null);
  };

  const handleRedirectRF = () => {
    window.open(redirectService.getRFUrl());
    setAnchorEl(null);
  };

  const handleDownloadAllPending = () => {
    window.open(pendingInvoicesService.buildDownloadAllPendingUrl());
    setAnchorEl(null);
  };

  const handleFileUpload = async (file: File) => {
    // call pending invoices service end point invoice-transition-summary
    const summary = await pendingInvoicesService.getInvoiceApprovalUploadSummary(file);
    setFileUpload(file);
    if (!invoiceApprovalSummary) {
      setInvoiceApprovalSummary(summary);
    }
  };

  const showTransitionDialog = () => {
    setIsShowingTransitionDialog(true);
    handleClose();
  };

  const onClosingTransitionDialog = () => {
    if (invoiceApprovalSummary) {
      setInvoiceApprovalSummary(undefined);
    }
    setIsShowingTransitionDialog(false);
  };

  return (
    <>
      <IconButton
        data-testid="menuButton"
        data-cy="menu-button"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        data-testid="simple-menu"
        data-cy="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem disabled>
          <TypeLabel>View by Product</TypeLabel>
        </MenuItem>
        <MenuItem data-testid="factoring-menu" data-cy="factoring-menu" onClick={handleRedirectFactoring}>
          Factoring{' '}
          <BaseChip
            data-testid="factoringChip"
            size="small"
            label={props.factoringCount}
            className={classes.iconPadding}
          />
        </MenuItem>
        <MenuItem data-testid="rf-menu" data-cy="rf-menu" onClick={handleRedirectRF}>
          Receivables Finance{' '}
          <BaseChip
            data-testid="rfChip"
            data-cy="rfChip"
            size="small"
            label={props.rfCount}
            className={classes.iconPadding}
          />
        </MenuItem>
        <MenuItem disabled>
          <TypeLabel>Downloads</TypeLabel>
        </MenuItem>
        <MenuItem data-testid="download-all-pending" data-cy="download-all-pending" onClick={handleDownloadAllPending}>
          Download All Pending
        </MenuItem>
        <MenuItem disabled>
          <TypeLabel>Uploads</TypeLabel>
        </MenuItem>
        <MenuItem>
          <input
            data-cy="upload-approval-file"
            data-testid="upload-approval-file"
            id={fileUploadId}
            accept={'.csv'}
            style={{ display: 'none' }}
            type={'file'}
            value={''}
            onChange={(event) => {
              if (event.target.files) {
                handleFileUpload(event.target.files[0]);
                showTransitionDialog();
              }
            }}
          />
          <label htmlFor={fileUploadId}>
            <TypeBase>Approval File</TypeBase>
          </label>
        </MenuItem>
      </Menu>
      {isShowingTransitionDialog && (
        <InvoiceApprovalTransitionSummary
          onClose={() => onClosingTransitionDialog()}
          file={fileUpload}
          invoiceSummary={invoiceApprovalSummary}
        />
      )}
    </>
  );
}

/**
 * Return an enabled button inidicating the number of RF or factoring count or return disabled
 * button if there are none
 * */
export const PendingInvoices: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { pendingInvoicesService, currencyService, redirectService } = useServices();

  const { isLoading, error, data } = useQuery('pendingInvoice', () => {
    return pendingInvoicesService.getTotalPendingInvoices();
  });
  const [isPrioritizing, setIsPrioritizing] = useState<boolean>(false);
  const [mutatePrioritize] = useMutation(
    () => {
      return pendingInvoicesService.prioritizeVerification();
    },
    { onSuccess: () => queryCache.invalidateQueries('pendingInvoice') },
  );
  if (isLoading || error || !data) {
    return (
      <TopBorderCard topBorderColor={theme.palette.warning.main}>
        <Skeleton variant="rect" height="150px" />
      </TopBorderCard>
    );
  }
  const {
    rfCount,
    factoringCount,
    unprioritizedCount,
    totalPendingSum,
    totalPendingInvoices,
    lastPrioritization,
  } = data;
  if (totalPendingInvoices !== 0) {
    let lastPrioritizedTime = lastPrioritization ? formatUTCAsCentral(lastPrioritization, 'MM/dd/yy p') : 'N/A';
    return (
      <TopBorderCard topBorderColor={theme.palette.warning.main}>
        <Grid container className={classes.topHalf}>
          <Grid container item md={6} lg={9} style={{ paddingBottom: '0.5rem' }}>
            <Grid item xs={4}>
              <TypeWidgetHeader>Invoices Pending </TypeWidgetHeader>
            </Grid>
            <Grid item xs={8}>
              {unprioritizedCount !== 0 ? (
                <BaseChip
                  data-testid="chip"
                  data-cy="chip"
                  className={classes.unprioriizedChip}
                  size="small"
                  label={unprioritizedCount + ' new'}
                />
              ) : null}
            </Grid>
            <TypeHelper data-cy="last-prioritized">Last Prioritized: {lastPrioritizedTime}</TypeHelper>
          </Grid>
          <Grid item md={6} lg={3} className={classes.prior}>
            <PrimaryButton
              data-testid="prioritize"
              data-cy="prioritize"
              variant="outlined"
              color="default"
              disabled={isPrioritizing}
              onClick={async () => {
                setIsPrioritizing(true);
                await mutatePrioritize();
                setIsPrioritizing(false);
              }}
            >
              <span
                role="img"
                aria-label="prioritize-icon"
                className={clsx(classes.iconPadding, isPrioritizing && classes.spinProgress)}
              >
                💅
              </span>
              Prioritize
            </PrimaryButton>
          </Grid>
        </Grid>
        <Box className={classes.body}>
          <Grid container className={classes.bottomHalf}>
            <Grid container item xs={11} spacing={2}>
              <Grid item xs={6} md={6} lg={3}>
                <TypeCallout data-cy="total-amount">
                  {currencyService.format('en', 'USD', totalPendingSum, { stripDecimals: true })}
                </TypeCallout>
                <TypeHelper>Total High Priority Amt</TypeHelper>
              </Grid>
              <Grid item xs={6} md={6} lg={3}>
                <TypeCallout data-cy="high-priority">{rfCount + factoringCount}</TypeCallout>
                <TypeHelper>High Priority</TypeHelper>
              </Grid>
              <Grid item xs={6} md={6} lg={3}>
                <TypeCallout data-cy="unprioritized">{unprioritizedCount}</TypeCallout>
                <TypeHelper>Unprioritized</TypeHelper>
              </Grid>
              <Grid item xs={6} md={6} lg={3}>
                <TypeCallout data-cy="total-invoices">{totalPendingInvoices}</TypeCallout>
                <TypeHelper>Total Invoices</TypeHelper>
              </Grid>
            </Grid>
            <Grid item xs={1} className={classes.productCountMenu}>
              <PendingInvoicesMenu factoringCount={factoringCount} rfCount={rfCount} />
            </Grid>
          </Grid>
        </Box>
      </TopBorderCard>
    );
  }
  // below state is "0 pending invoices"
  return (
    <TopBorderCard topBorderColor={theme.palette.warning.main}>
      <Grid container className={classes.topHalf}>
        <Grid container item xs={10}>
          <Grid item xs={12}>
            <TypeWidgetHeader>Invoices Pending </TypeWidgetHeader>
          </Grid>
          <TypeHelper>
            Last Updated: {format(new Date(), 'dd/MM/yy')} at {formatUTCAsCentral(new Date().toISOString(), 'p')}
          </TypeHelper>
        </Grid>

        <Grid item xs={2} className={classes.prior}>
          <span role="img" aria-label="prioritize-icon" className={classes.iconPadding}>
            💅
          </span>
          <TypeWidgetHeader>Prioritize</TypeWidgetHeader>
        </Grid>
      </Grid>

      <Box className={classes.body}>
        <Grid container className={classes.bottomHalfNull}>
          <Grid item xs={9} className={classes.prior}>
            <ExclamationCircleIcon fontSize="inherit" />
            <TypeWidgetHeader>No Invoices pending</TypeWidgetHeader>
          </Grid>
          <Grid item xs={3}>
            <Link
              data-testid="viewInvoices"
              data-cy="view-invoices"
              href="#"
              onClick={() => window.open(redirectService.getFactoringUrl())}
            >
              <TypeWidgetHeader>View all invoices</TypeWidgetHeader>
            </Link>
          </Grid>
        </Grid>
      </Box>
    </TopBorderCard>
  );
};
