import React from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter } from 'react-router-dom';
import { C2foComponentsRootProvider } from '@c2fo/react-components';
import { ErrorBoundary } from '@c2fo/react-components';
import { ServicesProvider } from './services';
import { Router } from './Router';
import { GlobalErrorPage } from './pages';
import { StoreTokenWrapper } from './components/StoreTokenWrapper/StoreTokenWrapper';

import faviconSm from '../assets/favicon-150x150.png';
import faviconLg from '../assets/favicon-300x300.png';

const App: React.FC = () => (
  <ErrorBoundary render={() => <GlobalErrorPage />}>
    <AppHead />
    <ServicesProvider>
      <C2foComponentsRootProvider>
        <BrowserRouter>
          <StoreTokenWrapper>
            <Router />
          </StoreTokenWrapper>
        </BrowserRouter>
      </C2foComponentsRootProvider>
    </ServicesProvider>
  </ErrorBoundary>
);

const AppHead: React.FC = () => {
  return (
    <Helmet>
      <title>Capfin Admin New</title>
      <base href="/" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      <link href={faviconLg} data-favicon-id="lg" rel="icon" type="image/x-icon" sizes="192x192" />
      <link href={faviconSm} data-favicon-id="sm" rel="icon" type="image/x-icon" sizes="32x32" />
      <link href={faviconLg} data-favicon-id="lg" rel="apple-touch-icon" />
    </Helmet>
  );
};

export default App;
