import React from 'react';
import {
  Card,
  makeStyles,
  TypeWidgetHeader,
  TypePageTitle,
  TypeHelper,
  Skeleton,
  EyeIcon,
  Link,
  Box,
  Grid,
  useTheme,
} from '@c2fo/react-components';
import { TopBorderCard } from '../TopBorderCard/TopBorderCard';
import { useAsync } from 'react-use';
import { useServices } from '../../services';

const useStyles = makeStyles((theme) => ({
  root: {
    borderTopStyle: 'solid',
    borderTopColor: theme.palette.warning.main,
    borderTopWidth: '10px',
    borderRadius: '5px',
    padding: theme.spacing(2),
  },
  negativeAmount: {
    fontSize: theme.typography.pxToRem(40),
    marginTop: theme.spacing(-10),
  },
  positiveAmount: {
    fontSize: theme.typography.pxToRem(40),
  },
  splitHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  unsettledClientsLink: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '& > *': {
      paddingLeft: theme.spacing(0.5),
    },
  },
}));

export const UnsettledBalances: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { settlementsService, currencyService, redirectService } = useServices();
  const asyncSettlementsServiceState = useAsync(async () => {
    return settlementsService.getUnsettledBalances();
  });
  if (asyncSettlementsServiceState.loading)
    return (
      <TopBorderCard topBorderColor={theme.palette.warning.main}>
        <Skeleton variant="rect" height="150px" />
      </TopBorderCard>
    );
  if (asyncSettlementsServiceState.error || !asyncSettlementsServiceState.value) return <div>can't get value </div>;
  const {
    negativeBalanceTotal,
    positiveBalanceTotal,
    negativeCount,
    positiveCount,
  } = asyncSettlementsServiceState.value;

  const goToUnsettledClients = () => {
    window.open(redirectService.getUnsettledClientsUrl());
  };

  return (
    <Card data-cy="unsettled-balance" className={classes.root}>
      <div className={classes.splitHeader}>
        <TypeWidgetHeader>Unsettled Balances</TypeWidgetHeader>
        <Link
          onClick={goToUnsettledClients}
          data-testid="view-unsettled-balances"
          className={classes.unsettledClientsLink}
        >
          <EyeIcon fontSize="small" />
          <TypeWidgetHeader customColor={theme.palette.secondary.main}>view</TypeWidgetHeader>
        </Link>
      </div>
      <Box>
        <Grid container>
          <Grid item md={6} lg={6}>
            <TypePageTitle data-testid="negative-unsettled-balance-amount" data-cy="negative-unsettled-balance-amount">
              <span className={classes.negativeAmount}>
                {currencyService.format('en', 'USD', negativeBalanceTotal, { stripDecimals: true })}
              </span>
            </TypePageTitle>
            <TypeHelper customColor="gray" data-testid="negativeClientNumber" data-cy="negative-client-number">
              {' '}
              Negative Balances From {negativeCount} Clients
            </TypeHelper>
          </Grid>
          <Grid item md={6} lg={6}>
            <TypePageTitle data-testid="positive-unsettled-balance-amount" data-cy="positive-unsettled-balance-amount">
              <span className={classes.positiveAmount}>
                {currencyService.format('en', 'USD', positiveBalanceTotal, { stripDecimals: true })}
              </span>
            </TypePageTitle>
            <TypeHelper customColor="gray" data-testid="positiveClientNumber" data-cy="positive-client-number">
              {' '}
              Positive Balances From {positiveCount} Clients
            </TypeHelper>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};
