import { tpfSupplierApiConfig } from '@c2fo/common-config';
import { BaseDataService } from '../BaseDataService/BaseDataService';
import { TpfSupplierApiHttpService } from '../TpfSupplierApiHttpService/TpfSupplierApiHttpService';
import { StagedReceiptUpload, StagedReceiptUploadStatus } from '../../schemas/pendingRemittance.schema';

const apiUrl = tpfSupplierApiConfig.TPF_SUPPLIER_API_ADMIN_BASE_URL;
export const pendingRemittanceBasePath = `${apiUrl}/receipts/uploads`;
export interface PendingRemittance {
  totalAmount: number;
  totalReceipts: number;
  oldestReceipt: string | null;
  newestReceipt: string | null;
  totalFiles: number;
}

export class PendingRemittanceService extends BaseDataService<StagedReceiptUpload> {
  constructor(protected httpService: TpfSupplierApiHttpService) {
    super(httpService, pendingRemittanceBasePath);
  }

  // eslint-disable-next-line max-statements
  async getPendingRemittance(): Promise<PendingRemittance> {
    try {
      const remittancePromise = this.query({
        status: StagedReceiptUploadStatus.PENDING,
      });
      const oldestReceiptPromise = this.query({
        size: 1,
        status: StagedReceiptUploadStatus.PENDING,
        sort: ['updated,DESC'],
      });
      const newestReceiptPromise = this.query({
        size: 1,
        status: StagedReceiptUploadStatus.PENDING,
        sort: ['updated,ASC'],
      });

      const [remittance, oldestReceiptDate, newestReceiptDate] = await Promise.all([
        remittancePromise,
        oldestReceiptPromise,
        newestReceiptPromise,
      ]);
      let totalAmount = 0;
      remittance.data.forEach((amount) => {
        totalAmount += amount.totalAmount;
      });
      let totalReceipts = 0;
      remittance.data.forEach((receipt) => {
        totalReceipts += receipt.totalCount;
      });
      let oldestReceipt = null;
      let newestReceipt = null;
      if (remittance.data.length !== 0) {
        oldestReceipt = oldestReceiptDate.data[0].lastUpdated;
        newestReceipt = newestReceiptDate.data[0].lastUpdated;
      }

      const totalFiles = remittance.data.length;
      return { totalAmount, totalReceipts, oldestReceipt, newestReceipt, totalFiles };
    } catch (error) {
      throw new Error(`stub error message: ${error}`);
    }
  }
}
