import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/polyfill-locales';
import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-numberformat/locale-data/en';
import '@formatjs/intl-numberformat/locale-data/fr';
import '@formatjs/intl-numberformat/locale-data/de';
import '@formatjs/intl-numberformat/locale-data/es';
import '@formatjs/intl-numberformat/locale-data/it';
import '@formatjs/intl-numberformat/locale-data/ru';
import '@formatjs/intl-numberformat/locale-data/tr';
import '@formatjs/intl-numberformat/locale-data/zh';

const { NumberFormat } = Intl;

export function formatNumber(locale = 'en', amount = 0, maximumFractionDigits = 3): string {
  return NumberFormat(locale, { maximumFractionDigits }).format(amount);
}
