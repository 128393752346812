import React, { useContext, useEffect } from 'react';
import {
  PrimaryButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  ModalContext,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  TypeBase,
} from '@c2fo/react-components';
import { InvoiceApprovalSummary } from '../../services/PendingInvoicesService/PendingInvoicesService';
import { useServices } from '../../services';
import { formatNumber } from '../../helpers/NumberHelpers';

const useStyles = makeStyles<Theme>((theme) => ({
  closeButton: {
    '&:hover': {
      background: 'red',
    },
    background: 'red',
  },
}));

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function InvoiceApprovalTransitionSummary(props: {
  onClose: () => void;
  file: undefined | File;
  invoiceSummary: undefined | InvoiceApprovalSummary;
}) {
  const classes = useStyles();
  const { setModalOpen: showModalBackdrop } = useContext(ModalContext);

  useEffect(() => {
    showModalBackdrop(true);
    return () => {
      showModalBackdrop(false);
    };
  });

  const { pendingInvoicesService } = useServices();

  const handleSubmit = async (file: undefined | File) => {
    // call the pending invoice service here end point invoice-transition
    if (file) {
      await pendingInvoicesService.updateInvoiceApprovalStatus(file);
    }
  };

  return (
    <Dialog data-testid="transition-summary-dialog" open={true} onClose={props.onClose} maxWidth={'lg'}>
      <DialogTitle>
        <TypeBase>Invoice Transition</TypeBase>
      </DialogTitle>
      <DialogContent dividers>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Status</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Count</TableCell>
              </TableRow>
            </TableHead>
            {props.invoiceSummary && (
              <TableBody>
                <TableRow>
                  <TableCell>Rejected</TableCell>
                  <TableCell align="right">{formatNumber('en', props.invoiceSummary.rejectedAmount, 2)}</TableCell>
                  <TableCell align="right">{props.invoiceSummary.rejectedCount}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Approved and verified</TableCell>
                  <TableCell align="right">
                    {formatNumber('en', props.invoiceSummary.approvedAndVerifiedAmount, 2)}
                  </TableCell>
                  <TableCell align="right">{props.invoiceSummary.approvedAndVerifiedCount}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Approved</TableCell>
                  <TableCell align="right">{formatNumber('en', props.invoiceSummary.approvedAmount, 2)}</TableCell>
                  <TableCell align="right">{props.invoiceSummary.approvedCount}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Not found/Not pending</TableCell>
                  <TableCell align="right">{'-'}</TableCell>
                  <TableCell align="right">{props.invoiceSummary.ignoredCount}</TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <PrimaryButton
          aria-label="submit"
          size="medium"
          onClick={async () => {
            handleSubmit(props.file);
            props.onClose();
          }}
        >
          Submit
        </PrimaryButton>
        <PrimaryButton
          className={classes.closeButton}
          aria-label="close"
          size="medium"
          color="primary"
          onClick={props.onClose}
        >
          Close
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
}
