import React from 'react';
import { TypeSectionHeader, makeStyles, TypePageTitle, Grid } from '@c2fo/react-components';
import { PendingInvoices } from '../PendingInvoices/PendingInvoices';
import { Advances } from '../Advances/Advances';
import { UnsettledBalances } from '../UnsettledBalances/UnsettledBalances';
import { PendingRemittance } from '../PendingRemittance/PendingRemittance';
import { ContractMaturity } from '../ContractMaturity/ContractMaturity';
import { UnmappedCustomers } from '../UnmappedCustomers/UnmappedCustomers';
import { CreditLimit } from '../CreditLimit/CreditLimit';
import { TimeSensitiveAlerts } from '../Alerts/Alerts';

const useStyles = makeStyles((theme) => ({
  typePageHeader: {
    paddingLeft: theme.spacing(35),
    paddingBottom: theme.spacing(10),
  },

  gridContainer: {
    paddingLeft: theme.spacing(35),
    paddingRight: theme.spacing(3),
  },
}));

export const TaskManagerGrid: React.FC = () => {
  const classes = useStyles();

  return (
    <>
      <TimeSensitiveAlerts />
      <div className={classes.typePageHeader}>
        <TypePageTitle>Task Center</TypePageTitle>
      </div>
      <Grid className={classes.gridContainer} container spacing={3}>
        <Grid item xs={11}>
          <TypeSectionHeader spacingBottom={2}>Funding Overview</TypeSectionHeader>
        </Grid>
        <Grid item xs={7} md={7} lg={7}>
          <PendingInvoices />
        </Grid>
        <Grid item xs={5} md={5} lg={5}>
          <PendingRemittance />
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <Advances />
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <UnsettledBalances />
        </Grid>
        <Grid item xs={12}>
          <TypeSectionHeader spacingBottom={2}>Client Overview</TypeSectionHeader>
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ContractMaturity />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <CreditLimit />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <UnmappedCustomers />
        </Grid>
      </Grid>
    </>
  );
};
