import { format } from 'date-fns';

/**
 * So, a couple of our dates are improperly stored or returned as UTC ISO8601 datetime strings
 * instead of having a timezone offset indicating they are really central time.  This function
 * hopefully encapsulates that madness so we can display these properly in central time.
 *
 * It does not yet address further localization, because this is our admin which is only accessed
 * in US Central time.
 */
export function formatUTCAsCentral(dateString: string, formatString: string): string {
  // we could try and re-add an offset here, but with daylight savings i'd rather just feed what's coming
  // in from the backend.
  // const sansUTC = dateString.endsWith('Z') ? dateString.substring(0, dateString.length - 1) : dateString;
  return format(new Date(dateString), formatString);
}
