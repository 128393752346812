import React from 'react';
import {
  Card,
  makeStyles,
  TypeWidgetHeader,
  TypeHelper,
  TypeCallout,
  Grid,
  Dialog,
  PrimaryButton,
  DialogActions,
  DialogContent,
  Link,
  TypographySystem,
  TypeBase,
  ExclamationCircleIcon,
  BaseChip,
  MoreVertIcon,
  IconButton,
  Menu,
  TypeLabel,
  MenuItem,
  Skeleton,
  useTheme,
} from '@c2fo/react-components';
import { useAsync } from 'react-use';
import { useServices } from '../../services';
import { TopBorderCard } from '../TopBorderCard/TopBorderCard';
import { format, differenceInDays, formatDistance } from 'date-fns';
import { Client } from '../../schemas';

const useStyles = makeStyles((theme) => ({
  root: {
    borderTopStyle: 'solid',
    borderTopColor: theme.palette.grey['300'],
    borderTopWidth: '10px',
    borderRadius: '5px',
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  amount: {
    fontSize: theme.typography.pxToRem(40),
  },
  titleOverflow: {
    display: 'block',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    '&> p': {
      display: 'block',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },
  sectionHeader: {
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.grey['400'],
    borderBottomWidth: theme.spacing(0.3),
    background: theme.palette.background.canvas,
    padding: theme.spacing(2),
  },
  header: {
    padding: theme.spacing(2),
    background: theme.palette.secondary.light,
  },
  companyGrid: {
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.grey['300'],
    borderBottomWidth: theme.spacing(0.1),
    padding: theme.spacing(2),
  },
  overdueChip: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '& svg': { fill: 'white' },
  },
  overdueGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  datePadding: {
    paddingRight: theme.spacing(2),
  },
  dialogContentOverride: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
  },
}));
export function DueDate(props: { expirationDate: string }) {
  const classes = useStyles();
  const daysUntilDue = differenceInDays(new Date(props.expirationDate), new Date());
  if (daysUntilDue <= 0) {
    return (
      <Grid item xs={4} className={classes.overdueGrid}>
        <BaseChip
          data-testid="overdue-chip"
          icon={<ExclamationCircleIcon />}
          className={classes.overdueChip}
          size="small"
          label={Math.abs(daysUntilDue) + ' days past due date'}
        />
      </Grid>
    );
  }
  return (
    <Grid
      container
      item
      xs={4}
      direction="column"
      justify="center"
      alignItems="flex-end"
      className={classes.datePadding}
    >
      <Grid>
        <TypeHelper>{format(new Date(props.expirationDate), 'MMM dd, yyyy')}</TypeHelper>
      </Grid>
      <Grid>
        <TypeHelper>{formatDistance(new Date(props.expirationDate), new Date())}</TypeHelper>
      </Grid>
    </Grid>
  );
}
export function CompanyContracts(props: {
  companyName: string;
  contact: string;
  expirationDate: string;
  uuid: string;
}) {
  const classes = useStyles();
  return (
    <Grid container item xs={12} className={classes.companyGrid}>
      <Grid item xs={7}>
        <TypeBase>{props.companyName}</TypeBase>
        <TypeHelper>Guarantor: {props.contact}</TypeHelper>
      </Grid>
      <DueDate expirationDate={props.expirationDate} />
      <Grid item xs={1} className={classes.overdueGrid}>
        <ViewContractMenu name={props.companyName} uuid={props.uuid} />
      </Grid>
    </Grid>
  );
}

export function ViewContractMenu(props: { name: string; uuid: string }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const { redirectService } = useServices();

  return (
    <>
      <IconButton
        data-testid="menu-open-Button"
        aria-controls="view-contract-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu data-testid="menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem disabled>
          <TypeLabel>View Contract</TypeLabel>
        </MenuItem>
        <MenuItem data-testid="view-contract" onClick={() => window.open(redirectService.getClientUrl(props.uuid))}>
          {props.name}
        </MenuItem>
      </Menu>
    </>
  );
}

export function ContractMaturityDialog(props: { next30: Client[]; next60: Client[]; next90: Client[]; total: number }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Link data-testid="contract-list" href="#" onClick={handleClickOpen}>
        <TypeWidgetHeader>View</TypeWidgetHeader>
      </Link>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={'paper'}
        aria-labelledby="Scroll dialog of contracts"
        aria-describedby="list of due contracts"
      >
        <span className={classes.header}>
          <TypeWidgetHeader customColor="white">Contracts Nearing Maturity ({props.total})</TypeWidgetHeader>
        </span>
        <DialogContent classes={{ root: classes.dialogContentOverride }}>
          <Grid container>
            <Grid item xs={12} md={6} lg={12} className={classes.sectionHeader}>
              <TypographySystem variant="h5">Due this month</TypographySystem>
            </Grid>
            {props.next30.map((contract) => (
              <CompanyContracts
                key={contract.uuid}
                companyName={contract.clientName}
                contact={contract.guarantor}
                expirationDate={contract.contractExpirationDate}
                uuid={contract.uuid}
              />
            ))}

            <Grid item xs={12} md={6} lg={12} className={classes.sectionHeader}>
              <TypographySystem variant="h5">Due in 31-60 days</TypographySystem>
            </Grid>
            {props.next60.map((contract) => (
              <CompanyContracts
                key={contract.uuid}
                companyName={contract.clientName}
                contact={contract.guarantor}
                expirationDate={contract.contractExpirationDate}
                uuid={contract.uuid}
              />
            ))}
            <Grid item xs={12} md={6} lg={12} className={classes.sectionHeader}>
              <TypographySystem variant="h5">Due in 61-90 days</TypographySystem>
            </Grid>
            {props.next90.map((contract) => (
              <CompanyContracts
                key={contract.uuid}
                companyName={contract.clientName}
                contact={contract.guarantor}
                expirationDate={contract.contractExpirationDate}
                uuid={contract.uuid}
              />
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <PrimaryButton data-testid="close-button" onClick={handleClose}>
            close
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export const ContractMaturity: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { clientService } = useServices();
  const asyncContractMaturityServiceState = useAsync(async () => {
    return clientService.getDueContracts();
  });
  if (asyncContractMaturityServiceState.loading)
    return (
      <TopBorderCard topBorderColor={theme.palette.grey['300']}>
        <Skeleton variant="rect" height="110px" />
      </TopBorderCard>
    );
  if (asyncContractMaturityServiceState.error || !asyncContractMaturityServiceState.value)
    return <div>can't get value </div>;

  const { next30, next60, next90 } = asyncContractMaturityServiceState.value;
  const totalNearingDue = next30.length + next60.length + next90.length;
  return (
    <Card className={classes.root}>
      <Grid container xs={12}>
        <Grid item md={5} lg={10}>
          <TypeCallout>{totalNearingDue}</TypeCallout>
        </Grid>
        <Grid item md={4} lg={8}>
          <TypeHelper>Clients nearing maturity</TypeHelper>
        </Grid>
        <Grid item md={3} lg={3}>
          <ContractMaturityDialog next30={next30} next60={next60} next90={next90} total={totalNearingDue} />
        </Grid>
        <Grid item md={5} lg={10} className={classes.titleOverflow}>
          <TypeHelper>
            {next30.length} clients within 30 days due and {totalNearingDue - next30.length} clients within 31-90 days
            due
          </TypeHelper>
        </Grid>
      </Grid>
    </Card>
  );
};
