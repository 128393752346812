/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom';

function render() {
  const App = require('./app/App').default;
  let root = document.getElementById('root')!;
  //   ReactDOM.unmountComponentAtNode(root);
  ReactDOM.render(<App />, root);
}

render();

/* istanbul ignore next */
if ((module as any).hot) {
  (module as any).hot.accept('./app/App', render);
}
