import React from 'react';
import { Card, makeStyles, createStyles, Theme, CardProps as MaterialCardProps } from '@c2fo/react-components';

type BorderCardProps = {
  topBorderColor: string;
} & MaterialCardProps;

const useStyles = makeStyles<Theme, Partial<BorderCardProps>>((theme) =>
  createStyles({
    root: {
      borderTopStyle: 'solid',
      borderTopWidth: '.6rem',
      borderTopColor: ({ topBorderColor }) => topBorderColor,
      borderRadius: '.3rem',
    },
  }),
);

/**
 * Simple wrapper to expose a styled top border card of any desired coor
 */
export function TopBorderCard({ topBorderColor, ...props }: BorderCardProps): React.ReactElement {
  const classes = useStyles({ topBorderColor });
  return <Card classes={classes} {...props} />;
}
