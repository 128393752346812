import React from 'react';
import { useQuery } from 'react-query';
import {
  makeStyles,
  TypeWidgetHeader,
  TypePageTitle,
  TypeHelper,
  EyeIcon,
  Link,
  Skeleton,
  useTheme,
} from '@c2fo/react-components';
import { TopBorderCard } from '../TopBorderCard/TopBorderCard';
import { useServices } from '../../services';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  advanceLink: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '& > *': {
      paddingLeft: theme.spacing(0.5),
    },
  },
  amount: {
    fontSize: theme.typography.pxToRem(40),
  },
  splitHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export const Advances: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { advanceService, currencyService, redirectService } = useServices();
  const { isLoading, error, data } = useQuery('pendingAdvances', async () => {
    return advanceService.getTotalPendingAdvances();
  });
  const goToAdvances = () => {
    window.open(redirectService.getAdvancesUrl());
  };
  if (isLoading || error || !data) {
    return (
      <TopBorderCard topBorderColor={theme.palette.warning.main}>
        <Skeleton variant="rect" height="150px" />
      </TopBorderCard>
    );
  }
  const { advanceTotal, count } = data;

  return (
    <TopBorderCard
      data-cy="pending-advance-requests"
      topBorderColor={theme.palette.warning.main}
      className={classes.root}
    >
      <div className={classes.splitHeader}>
        <TypeWidgetHeader>Pending Advance Requests</TypeWidgetHeader>
        <Link onClick={goToAdvances} data-testid="view-advances" className={classes.advanceLink}>
          <EyeIcon fontSize="small" />
          <TypeWidgetHeader customColor={theme.palette.secondary.main}>view</TypeWidgetHeader>
        </Link>
      </div>
      <TypePageTitle data-testid="amount" data-cy="amount">
        <span className={classes.amount}>
          {currencyService.format('en', 'USD', advanceTotal, { stripDecimals: true })}
        </span>
      </TypePageTitle>
      <TypeHelper customColor="gray" data-testid="clientNumber" data-cy="client-number">
        {' '}
        From {count} Clients
      </TypeHelper>
    </TopBorderCard>
  );
};
