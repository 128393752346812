import { makeServices, ActiveDirectoryAuthService, HttpService } from '@c2fo/react-services';
import { userAuthServiceConfig } from '@c2fo/common-config';
import { CurrencyService } from '@c2fo/react-services';
import { CapFinTokenService } from './CapFinTokenService/CapFinTokenService';
import { ClientService } from './ClientService/ClientService';
import { RFClientBalanceService } from './ClientService/RFClientBalanceService';
import { FactoringClientBalanceService } from './ClientService/FactoringClientBalanceService';
import { ClientDebtorService } from './ClientDebtorService/ClientDebtorService';
import { PendingInvoiceService } from './PendingInvoicesService/PendingInvoicesService';
import { TpfSupplierApiHttpService } from './TpfSupplierApiHttpService/TpfSupplierApiHttpService';
import { AdvanceService } from './AdvanceService/AdvanceService';
import { RedirectService } from './RedirectService/RedirectService';
import { PendingRemittanceService } from './PendingRemittanceService/PendingRemittanceService';
import { SettlementsService } from './SettlementsService/SettlementsService';
import { ClockService } from './ClockService/ClockService';
/**
 * Create service singletons.
 */
const capFinTokenService = new CapFinTokenService('C2FOAuthToken');
const httpService = new HttpService(capFinTokenService);
const tpfSupplierApiHttpService = new TpfSupplierApiHttpService(capFinTokenService);
const clientService = new ClientService(tpfSupplierApiHttpService);
const clientDebtorService = new ClientDebtorService(tpfSupplierApiHttpService);
const pendingInvoicesService = new PendingInvoiceService(tpfSupplierApiHttpService);
const authService = new ActiveDirectoryAuthService(
  capFinTokenService,
  httpService,
  userAuthServiceConfig.USER_AUTH_SERVICE_URL,
);
const clockService = new ClockService();
const advanceService = new AdvanceService(tpfSupplierApiHttpService, clockService);
const currencyService = new CurrencyService();
const redirectService = new RedirectService(capFinTokenService);
const pendingRemittanceService = new PendingRemittanceService(tpfSupplierApiHttpService);
const rfClientService = new RFClientBalanceService(tpfSupplierApiHttpService);
const factoringClientService = new FactoringClientBalanceService(tpfSupplierApiHttpService);
const settlementsService = new SettlementsService(tpfSupplierApiHttpService, clockService);

export interface Services {
  authService: ActiveDirectoryAuthService;
  capFinTokenService: CapFinTokenService;
  httpService: HttpService;
  tpfSupplierApiHttpService: TpfSupplierApiHttpService;
  clientService: ClientService;
  clientDebtorService: ClientDebtorService;
  pendingInvoicesService: PendingInvoiceService;
  advanceService: AdvanceService;
  currencyService: CurrencyService;
  redirectService: RedirectService;
  pendingRemittanceService: PendingRemittanceService;
  rfClientService: RFClientBalanceService;
  factoringClientService: FactoringClientBalanceService;
  settlementsService: SettlementsService;
  clockService: ClockService;
}

export const { ServicesProvider, useServices, ServicesContext } = makeServices<Services>({
  authService,
  capFinTokenService,
  httpService,
  tpfSupplierApiHttpService,
  clientService,
  clientDebtorService,
  pendingInvoicesService,
  advanceService,
  currencyService,
  redirectService,
  pendingRemittanceService,
  rfClientService,
  factoringClientService,
  settlementsService,
  clockService,
});
