import React, { useState } from 'react';
import {
  makeStyles,
  TypeBase,
  AngleUpIcon,
  AngleDownIcon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Divider,
  TypeSubsectionHeader,
  AccountCircleIcon,
  AccountMultipleIcon,
  AccountPlusIcon,
  BriefcaseIcon,
  CompareHorizontalIcon,
  FilterVariantIcon,
  SpedometerIcon,
  TrendingUpIcon,
  Link,
} from '@c2fo/react-components';
import { useServices } from '../../services';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 260,
    height: '100%',
    backgroundColor: theme.palette.success.main,
    fontColor: 'white',
    position: 'fixed',
  },
  nested: {
    paddingLeft: theme.spacing(6),
  },
  title: {
    backgroundColor: theme.palette.success.main,
    width: '100%',
    maxWidth: 260,
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  subheader: {
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  filters: {
    backgroundColor: theme.palette.grey['600'],
  },
  listItemText: {
    marginLeft: '-1rem',
  },
  accountIcon: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.success.main,
    width: '100%',
    maxWidth: 260,
    alignItems: 'center',
  },
}));

export const SideNestedNav: React.FC = () => {
  const classes = useStyles();
  const { redirectService } = useServices();
  const [openTaskCenter, setOpenTaskCenter] = useState(true);
  const [openCapfin, setOpenCapfin] = useState(true);
  const [openRf, setOpenRf] = useState(true);
  const [openFactoring, setOpenFactoring] = useState(true);
  const [openOnboarding, setOpenOnboarding] = useState(true);
  const [openLending, setOpenLending] = useState(true);
  const [openConnect, setOpenConnect] = useState(true);
  const [openFilters, setOpenFilters] = useState(true);

  const handleTaskCenterClick = () => {
    setOpenTaskCenter(!openTaskCenter);
  };
  const handleCapfinClick = () => {
    setOpenCapfin(!openCapfin);
  };
  const handleRfClick = () => {
    setOpenRf(!openRf);
  };
  const handleFactoringClick = () => {
    setOpenFactoring(!openFactoring);
  };
  const handleOnboardingClick = () => {
    setOpenOnboarding(!openOnboarding);
  };
  const handleLendingClick = () => {
    setOpenLending(!openLending);
  };
  const handleConnectClick = () => {
    setOpenConnect(!openConnect);
  };
  const handleFiltersClick = () => {
    setOpenFilters(!openFilters);
  };

  return (
    <>
      <div className={classes.title}>
        <AccountCircleIcon className={classes.accountIcon} htmlColor={'black'} />
        <TypeSubsectionHeader customColor="black">Site Options</TypeSubsectionHeader>
      </div>
      <List
        component="nav"
        aria-labelledby="subheader-for-reset-settings"
        subheader={
          <div className={classes.subheader}>
            <TypeBase customColor="black" component="div" id="reset-settings-subheader">
              reset settings
            </TypeBase>
          </div>
        }
        className={classes.root}
      >
        <Divider color="black" />
        <ListItem button onClick={handleTaskCenterClick} data-testid={'task-center-nav'}>
          <ListItemIcon>
            <SpedometerIcon htmlColor={'white'} />
          </ListItemIcon>
          <ListItemText
            className={classes.listItemText}
            primary={<TypeBase customColor={'white'}>Task Center</TypeBase>}
          />
        </ListItem>

        <ListItem color={'white'} button onClick={handleCapfinClick} data-testid={'capfin-nav'}>
          <ListItemIcon>
            <CompareHorizontalIcon htmlColor={'white'} />
          </ListItemIcon>
          <ListItemText
            className={classes.listItemText}
            disableTypography
            primary={<TypeBase customColor={'white'}>Capital Finance</TypeBase>}
          />
          {openCapfin ? <AngleUpIcon htmlColor={'white'} /> : <AngleDownIcon htmlColor={'white'} />}
        </ListItem>
        <Collapse in={openCapfin} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested}>
              <Link href={redirectService.getCapfinClientsUrl()}>
                <ListItemText disableTypography primary={<TypeBase customColor={'white'}>Clients</TypeBase>} />
              </Link>
            </ListItem>
            <ListItem button className={classes.nested}>
              <Link href={redirectService.getCapfinDebtorsUrl()}>
                <ListItemText primary={<TypeBase customColor={'white'}>Debtors</TypeBase>} />
              </Link>
            </ListItem>
            <ListItem button className={classes.nested}>
              <Link href={redirectService.getUnmappedCustomersUrl()}>
                <ListItemText primary={<TypeBase customColor={'white'}>Client-Debtor Mapping</TypeBase>} />
              </Link>
            </ListItem>
            <ListItem button className={classes.nested}>
              <Link href={redirectService.getCapfinReceiptsUrl()}>
                <ListItemText primary={<TypeBase customColor={'white'}>Receipts</TypeBase>} />
              </Link>
            </ListItem>
            <ListItem button className={classes.nested}>
              <Link href={redirectService.getCapfinReferenceRatesUrl()}>
                <ListItemText primary={<TypeBase customColor={'white'}>Admin</TypeBase>} />
              </Link>
            </ListItem>
          </List>
        </Collapse>

        <ListItem button onClick={handleRfClick} data-testid={'rf-nav'}>
          <ListItemIcon>
            <TrendingUpIcon htmlColor={'white'} />
          </ListItemIcon>
          <ListItemText
            className={classes.listItemText}
            primary={<TypeBase customColor={'white'}>Receivables Finance</TypeBase>}
          />
          {openRf ? <AngleUpIcon htmlColor={'white'} /> : <AngleDownIcon htmlColor={'white'} />}
        </ListItem>
        <Collapse in={openRf} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested}>
              <Link href={redirectService.getRFClientsUrl()}>
                <ListItemText primary={<TypeBase customColor={'white'}>Clients</TypeBase>} />
              </Link>
            </ListItem>
            <ListItem button className={classes.nested}>
              <Link href={redirectService.getRFUrl()}>
                <ListItemText primary={<TypeBase customColor={'white'}>Invoices</TypeBase>} />
              </Link>
            </ListItem>
            <ListItem button className={classes.nested}>
              <Link href={redirectService.getAdvancesUrl()}>
                <ListItemText primary={<TypeBase customColor={'white'}>Advances</TypeBase>} />
              </Link>
            </ListItem>
            <ListItem button className={classes.nested}>
              <Link href={redirectService.getRFFeesUrl()}>
                <ListItemText primary={<TypeBase customColor={'white'}>Fees</TypeBase>} />
              </Link>
            </ListItem>
            <ListItem button className={classes.nested}>
              <Link href={redirectService.getRFBalanceAdjustmentsUrl()}>
                <ListItemText primary={<TypeBase customColor={'white'}>Balance Adjustments</TypeBase>} />
              </Link>
            </ListItem>
          </List>
        </Collapse>

        <ListItem button onClick={handleFactoringClick} data-testid={'factoring-nav'}>
          <ListItemIcon>
            <TrendingUpIcon htmlColor={'white'} />
          </ListItemIcon>
          <ListItemText
            className={classes.listItemText}
            primary={<TypeBase customColor={'white'}>Factoring</TypeBase>}
          />
          {openFactoring ? <AngleUpIcon htmlColor={'white'} /> : <AngleDownIcon htmlColor={'white'} />}
        </ListItem>
        <Collapse in={openFactoring} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested}>
              <Link href={redirectService.getFactoringClientsUrl()}>
                <ListItemText primary={<TypeBase customColor={'white'}>Clients</TypeBase>} />
              </Link>
            </ListItem>
            <ListItem button className={classes.nested}>
              <Link href={redirectService.getFactoringUrl()}>
                <ListItemText primary={<TypeBase customColor={'white'}>Invoices</TypeBase>} />
              </Link>
            </ListItem>
            <ListItem button className={classes.nested}>
              <Link href={redirectService.getUnsettledClientsUrl()}>
                <ListItemText primary={<TypeBase customColor={'white'}>Settlements</TypeBase>} />
              </Link>
            </ListItem>
            <ListItem button className={classes.nested}>
              <Link href={redirectService.getFactoringFeesUrl()}>
                <ListItemText primary={<TypeBase customColor={'white'}>Fees</TypeBase>} />
              </Link>
            </ListItem>
          </List>
        </Collapse>

        <ListItem button onClick={handleOnboardingClick} data-testid={'onboarding-nav'}>
          <ListItemIcon>
            <AccountPlusIcon htmlColor={'white'} />
          </ListItemIcon>
          <ListItemText
            className={classes.listItemText}
            primary={<TypeBase customColor={'white'}>Onboarding</TypeBase>}
          />
          {openOnboarding ? <AngleUpIcon htmlColor={'white'} /> : <AngleDownIcon htmlColor={'white'} />}
        </ListItem>
        <Collapse in={openOnboarding} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested}>
              <Link href={redirectService.getOnboardingRFUrl()}>
                <ListItemText primary={<TypeBase customColor={'white'}>Receivables Finance</TypeBase>} />
              </Link>
            </ListItem>
          </List>
        </Collapse>

        <ListItem className={classes.filters} button onClick={handleFiltersClick} data-testid={'filters-nav'}>
          <ListItemIcon>
            <FilterVariantIcon htmlColor={'white'} />
          </ListItemIcon>
          <ListItemText className={classes.listItemText} primary={<TypeBase customColor={'white'}>Filters</TypeBase>} />
        </ListItem>
        <Collapse in={openFilters} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested}>
              <ListItemText primary={<TypeBase customColor={'white'}>PLACEHOLDER FOR FILTERS</TypeBase>} />
            </ListItem>
          </List>
        </Collapse>
      </List>
    </>
  );
};
